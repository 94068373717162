import { deleteRequest, getRequest, postRequest, putRequest } from '..';

/**
 * Api call
 */
class orderService {

    details = async ({uid}) => {
        return await getRequest(`customer/order?uid=${uid}`);
    }

    list = async (urlParam = {}, payload) => {
        return await getRequest('customer/order/list', urlParam);
    }

    create = async (payload) => {
        return await postRequest('customer/order', payload);
    }

    cartDetails = async (payload) => {
        return await getRequest('cart', payload);
    }

    trackingWiseList = async () => {
        return await getRequest('manager/order/tracking-wise-list');
    }

}

const instance = new orderService();

export default instance;