import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TbFaceIdError } from "react-icons/tb";



export const Icloader=(props)=>{
    return <AiOutlineLoading3Quarters style={props?.style}/>
}

export const IcErrorSquare=(props)=>{
    return <TbFaceIdError style={props?.style}/>
}