import React, { useEffect, useState } from 'react'
import { Link, Navigate, NavLink, useNavigate } from 'react-router-dom'
import RootLayout from '../Layout'
import { RiArrowDropDownFill } from '@react-icons/all-files/ri/RiArrowDropDownFill.esm';
import Button from '../commonComponent/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteParoducts, getProduct} from '../../store/productSlice';
import ProductCard from '../commonComponent/Product/Product';
import productService from '../../services/axios/product';

function ComingSoon() {

    const dispatch = useDispatch();

    const [productList, setProductList] = useState([]);


    useEffect(() => {
        dispatch(getFavoriteParoducts({}))
    }, [])

    useEffect(() => {
        productService.list({category:'Pret',pageSize:100})
            .then((resp) => {
                setProductList(resp?.data?.response?.list);
            })
    }, [])

    return (
        <RootLayout >
            <div className="pretProductSection w-100 flex-centered flex-column gap-1">
                <div className="pretProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <br/>
                    <br/>

                    <h1 style={{fontSize:'3em'}}>Coming Soon</h1>
                    <p>Stay Connected</p>
                    
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>

            </div>
        </RootLayout>

    )
}

export default ComingSoon
