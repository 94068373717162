import React, { useEffect, useRef, useState } from "react";
import "./Cart.css";
import Button from "../commonComponent/Button/Button";
import RootLayout from "../Layout";
import Image from "../commonComponent/Image/Image";

import { BsTrashFill } from "@react-icons/all-files/bs/BsTrashFill.esm";
import { NavLink, useNavigate } from "react-router-dom";
import { FiHeart } from "@react-icons/all-files/fi/FiHeart.esm";
import { useDispatch, useSelector } from "react-redux";
import BackPageCompo from "../commonComponent/BackPageCompo/BackPageCompo";
import orderService from "../../services/axios/order";
import { toCurrencyFormat } from "../CommonFunction/CurrencyFormat";
import { removeCart } from "../../store/orderSlice";
import { PRODUCT } from "../../constants";
import Modal from "../commonComponent/Modal";
import CustDetailForm from "../CustDetailForm/Index";
import DataError from "../components/DataError";
import { btToast } from "../components/BtToast";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart.esm";
import { addFavorite, removeFavorite } from "../../store/productSlice";



const Cart = (props) => {
  const pform = useRef(null);
  const [ppDetails, setPpDetails] = useState({});
  const { cartList } = useSelector((store) => store.order);
  const [loading, setLoading] = useState(false)
  const { auth } = useSelector((store) => store.auth);
  const [cartDetail, setCartDetailList] = useState([])
  const [imagePath, setImagePath] = useState(null)
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);

  useEffect(() => {
    if (cartList.length > 0) {
      orderService
        .cartDetails({ product: cartList })
        .then((resp) => {
          setCartDetailList(resp?.data?.response)
        })
    }

  }, [cartList])


  const handleCheckout = () => {
    setLoading(true)
    if (auth?.token == null) {
      navigate('/login?returnTo=cart')
    }

    return orderService.create({
      products: cartList,
    })
      .then((resp) => {
        setPpDetails(resp?.data?.response);

        setTimeout(() => {
          pform.current.submit();
          // setLoading(false)
        }, 100);
      })
      .catch((err) => {
        setLoading(false)
        if (err?.response?.data?.message === "PROFILE_NOT_EXIST") {
          navigate('/personal-info?returnTo=cart')
          btToast({ message: "Kindly fill out your details to begin your journey with us." });
        } else {
          btToast({ message: err?.response?.data?.message });
        }
      })
  };

  if (cartList?.length == 0) {
    return <RootLayout>
      <DataError
        paper
        fullContent
        title="You don't have any product in your cart."
      />
    </RootLayout>
  }

  return (
    <RootLayout>

      <form ref={pform} method="POST" action={ppDetails?.paymentUrl}>
        <input type='hidden' value={ppDetails?.encRequest} name="encRequest" />
        <input type='hidden' value={ppDetails?.access_code} name="access_code" />
      </form>
      <div className="cartSection flex-centered flex-column w-100 gap-2">
        <BackPageCompo
          href="/"
          title="Cart"
        />
        <div className="w-80 flex gap-2">
          <div className="cartProductList w-100">
            <h2>Cart</h2>
            {/* <br/> */}
            <div className="flex-column">
              {cartDetail?.product?.map((itm) => {
                return <CartProduct details={itm} path={cartDetail?.thumb_path} />;
              })}

            </div>
          </div>
          <div className="cartProductTotal flex-column gap-1">
            <div className="flex-between ">
              <h3>Subtotal</h3>
              <h3>{toCurrencyFormat(cartDetail?.subTotal)}</h3>
            </div>
            {/* <div className="flex-between ">
              <p>Discount</p>
              <p>(20%) - &#8377; 16000.00</p>
            </div> */}
            {/* <div className="flex-between ">
              <p>Delivery</p>
              <p>&#8377; 0.00</p>
            </div> */}
            {/* <div className="flex-between ">
              <p>Tax</p>
              <p>{toCurrencyFormat(cartDetail?.taxTotal)}</p>
            </div> */}

            <hr />

            <div className="flex-between ">
              <h2>Total</h2>
              <h2>{toCurrencyFormat(cartDetail?.grandTotal)}</h2>
            </div>

            <Button
              onClick={handleCheckout}
              title="Proceed to checkout"
              variant="btn-darkFill"
              buttonLoading={loading}
            />

            {/* <Button
              onClick={()=>setModalToggle((prev) => !prev)}
              title="Add Detail"
              variant="btn-darkFill"
            /> */}

            <Modal open={modalToggle} onClose={() => setModalToggle((prev) => !prev)}>
              <CustDetailForm />
            </Modal>
            <Button title="Continue Shopping" variant="btn-sec" onClick={() => { navigate('/categories/pret') }} />
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default Cart;


const CartProduct = ({ details, path }) => {
  const [imgSrc, setImgSrc] = useState(path + details?.primary_image)
  const dispatch = useDispatch();
  const { favoritesList} = useSelector(store => store.product);
  const navigate = useNavigate()
  const isLoggedIn = useSelector(store => store.auth)

  return (
    <div className="">
      <hr />
      <div className="cartProduct flex gap-1">
        <div className="cartProductImg" style={{cursor: 'pointer'}} onClick={()=>navigate(`/product-detail/${details?.id}`)}>
          <Image src={imgSrc} onError={() => setImgSrc(PRODUCT.defaultImg)} width="100%" />
        </div>
        <div className="cartProductTxt w-100 flex-column gap-2">
          <div className="flex-between flex-centered">
            <div className="cartProductName flex-column gap-1">
              <NavLink to={`/product-detail/${details?.id}`}><h3>{details?.product_name}</h3></NavLink>
              {window.innerWidth > 600 ? (
                <div className="cartProductPrice flex">
                  <p>{toCurrencyFormat(details?.sale_price)}</p>
                  <p>|</p>
                  <p className="cartProductAvailability">In Stock</p>
                </div>
              ) : (
                ""
              )}
            </div>
            {window.innerWidth < 600 ? (
              <div className="cartProductPrice flex-centered cartProductTotalPrice cartProductTotalPriceMob">
                <div className="cartProductFavIcon">
                  {
                    favoritesList?.includes(details?.id)
                        ? 
                        <FaHeart
                            onClick={()=> (isLoggedIn)?dispatch(removeFavorite(details?.id)):navigate(`/login?returnTo=cart`)}
                            style={{
                                fill: 'red'
                            }}
                        />
                        :
                        <FiHeart
                            onClick={()=> (isLoggedIn)?dispatch(addFavorite(details?.id)):navigate(`/login?returnTo=cart`)}
                            style={{
                                width: '100%',
                                color: 'grey'
                                // height: '100',
                            }}
                        />
                    }
                </div>
                <h2> {toCurrencyFormat(details?.sale_price)}</h2>
                <p>|</p>
                <p className="cartProductAvailability">In Stock</p>
              </div>
            ) : (
              <div className="cartProductTotalPrice">
                <h2>{toCurrencyFormat(details?.sale_price)}</h2>
              </div>
            )}
          </div>

          <div className="cartProductBottomDiv flex-between">
            <div className="cartProductSaveOrDelDiv flex-centered gap-1">
              {window.innerWidth < 600 ? (
                <div className="cartProductDelete">
                  <NavLink to="/">
                    <BsTrashFill
                      style={{
                        fill: "grey",
                      }}
                    />
                  </NavLink>
                </div>
              ) : (
                <>
                  <div className="">
                    {
                    favoritesList?.includes(details?.id)
                        ? 
                        <FaHeart
                            onClick={()=> (isLoggedIn)?dispatch(removeFavorite(details?.id)):navigate(`/login?returnTo=cart`)}
                            style={{
                                fill: 'red'
                            }}
                        />
                        :
                        <FiHeart

                            onClick={()=> (isLoggedIn)?dispatch(addFavorite(details?.id)):navigate(`/login?returnTo=cart`)}
                            style={{
                                width: '100%',
                                color: 'grey'
                                // height: '100',
                            }}
                        />
                    }
                  </div>
                  <p>|</p>
                  <div className="">
                    <NavLink onClick={() => dispatch(removeCart(details?.id))}>
                      <BsTrashFill
                        style={
                          {
                            // fill: 'grey',
                          }
                        }
                      />
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddOrRemoveQuantity = ({ onValueChange }) => {
  const [count, setCount] = useState(1);
  const pRef = useRef(null);


  const handleClickAdd = (e) => {
    e.preventDefault();

    if (count >= 10) {
      setCount(10);
    } else {
      setCount(count + 1);
    }
  };

  const handleClickSubs = (e) => {
    e.preventDefault();
    if (count <= 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    if (pRef.current) {
      // onValueChange(pRef.current.innerText);
    }
  }, [handleClickAdd, handleClickSubs]);

  return (
    <>
      <div className="addOrRemoveQuantity flex-centered gap-1">
        <button className="" onClick={handleClickSubs}>
          -
        </button>
        <p ref={pRef}>{count}</p>
        <button className="" onClick={handleClickAdd}>
          +
        </button>
      </div>
    </>
  );
};
