import { useState, useEffect } from "react";
import "./style.css";
export const BtConfirm = () => {
  const [data, setData] = useState(null);
  const [config, setConfig] = useState({
    acceptTitle:'Accept',
    cancelTitle:'Cancel',
  })
  const handleOpenConfirm = (data) => {
    setData(data?.detail);
    setConfig(prev=>{
      return {...prev,...data?.detail?.config}
    })
  };

  useEffect(() => {
    window.addEventListener("openBtConfirm", (data) => {
      handleOpenConfirm(data);
    });
    return () => {
      window.removeEventListener("openBtConfirm", () => {
        setData(null);
      });
    };
  }, [handleOpenConfirm]);

  const handleCancel=()=>{
    setData(null)
    if(data?.onCancel){
      data?.onCancel()
    }
  }
  if (!data) {
    return false;
  }
  return (
    <>
      <div className="bt-alert-background"></div>
      <div
        className={`bt-confirm type-${data?.type}`}
        onClick={() => setData(null)}
      >
        <div className="bt-confirm-div">
          <h2>Are You Sure?</h2>
          <p style={{ color: data?.type ? "white" : 'black' }}>{data?.message}</p>
          
          <div className="bt-buttons-container">
            <button onClick={data?.onAccept} style={{background: config?.acceptBackground ? config?.acceptBackground : "green", color: 'white'}}>
                {config.acceptTitle}
            </button>
            <button onClick={handleCancel} className={config?.cancelBackground ? "" : "cancel"}>
                {config.cancelTitle}
            </button>
          </div>
        </div>
        {/* {data?.title?<p style={{fontSize:'1.2em',fontWeight:'400',color:'white'}}>{data?.title}</p>:null} */}
        
      </div>
    </>
  );
};

export const confirm = (message,onAccept,onCancel = null,config = {}) => {
  const event = new CustomEvent("openBtConfirm", {
    
    detail: {message,onAccept,onCancel,config},
  });
  window.dispatchEvent(event);
};

