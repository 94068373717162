import { getRequest, postRequest } from './../../axios';

/**
 * Api call
 */
class customerService {

    getDetails = async (urlParam = {}) => {
        return await getRequest('customer/profile', urlParam);
    }

    list = async (urlParam = {}) => {
        return await getRequest('customer/list', urlParam);
    }

    updateProfile = async (urlParam = {}) => {
        return await postRequest('customer/profile-update', urlParam);
    }
}

const instance = new customerService();

export default instance;