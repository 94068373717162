import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class authService {

    getLogin = async (urlParam = {}) => {
        return await postRequest('customer/login', urlParam);
    }

    getLogout = async (urlParam = {}) => {
        return await postRequest('customer/logout', urlParam);
    }

    getSignup = async (urlParam = {}) => {
        return await postRequest('customer/sign-up', urlParam);
    }

    forgotPassword = async (urlParam = {}) => {
        return await postRequest('customer/forgot-password', urlParam);
    }

    verifyForgotOtp = async (urlParam = {}) => {
        return await postRequest('customer/reset-password', urlParam);
    }

    verifyEmail = async (urlParam = {}) => {
        return await postRequest('customer/verify-email', urlParam);
    }

    updatePassword = async (urlParam = {}) => {
        return await postRequest('customer/update-password', urlParam);
    }
}

const instance = new authService();

export default instance;