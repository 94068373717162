import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Product.css'

import { FiHeart } from '@react-icons/all-files/fi/FiHeart.esm'
import { FaHeart } from '@react-icons/all-files/fa/FaHeart.esm'
import { addFavorite, removeFavorite } from '../../../store/productSlice';
import { removeCart,addCart } from '../../../store/orderSlice';

import { PiBagLight } from "react-icons/pi";
import { PiBagFill } from "react-icons/pi";
import { toCurrencyFormat } from '../../CommonFunction/CurrencyFormat';

import { PRODUCT } from '../../../constants';

const ProductCard = ({details,path}) => {
    const [imgSrc, setImgSrc] = useState(path+details?.primary_image)
    const { favoritesList} = useSelector(store => store.product);
    const { isLoggedIn } = useSelector(store => store.auth);
    const {cartList}=useSelector(store=>store.order);
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const productId = parseInt(details?.id)

    const IconStyle = {
        borderRadius: '50%',
        padding: '0.5em',
        width: '2em',
        height: '2em',
        border: '1px solid var(--header-dark)',
        cursor: 'pointer',
    }

    const productAbsStyle = {
        overflow: 'hidden',
    }

    return (
        <div className='productSection'
            style={{
        
            }}
        >
            <div className='productImgDiv w-100 flex-centered'
                onClick={() => { navigate(`/product-detail/${productId}`) }}
                style={{
                    cursor: 'pointer',
                    paddingTop:PRODUCT.paddingTop
                }}
            >
                <div style={productAbsStyle} className='productAbs w-100 flex-centered'>
                    <img src={imgSrc} onError={()=>setImgSrc(PRODUCT.defaultImg)}  height={details?.height} alt={details?.alt} />
                </div>
            </div>
            <div className='productTxtDiv'>
                <p className='description-txt'
                    style={{
                        fontSize: '1.2em',
                        fontWeight: '300',
                        width: '100%',
                        textAlign: 'left',
                        margin: '1em 0em',
                        height: '1.2em',
                        overflow: 'hidden',
                    }}
                >{details?.name}</p>
                <div className='flex flex-between w-100'>
                    <h2 className='p-txt'
                        style={{
                            fontSize: window.innerWidth < 1200 ? '1.1em' : '1.3em',
                            fontWeight: '500'
                        }}
                    > {toCurrencyFormat(details?.sale_price)}</h2>
                    <div className='ProductIconsDiv flex gap-1' >
                        <div className='Icon flex-centered' style={IconStyle}
                        >
                            {
                                favoritesList?.includes(productId)
                                    ? 
                                    <FaHeart
                                        onClick={()=> (isLoggedIn)?dispatch(removeFavorite(productId)) : navigate('/login?returnTo=categories/pret')}
                                        style={{
                                            fill: 'red'
                                        }}
                                    />
                                    :
                                    <FiHeart

                                        onClick={()=> (isLoggedIn)?dispatch(addFavorite(productId)) : navigate('/login?returnTo=categories/pret')}
                                        style={{
                                            width: '100%',
                                            // height: '100',
                                        }}
                                    />
                            }
                        </div>
                        <div className='Icon flex-centered' style={IconStyle}>
                        {
                                (cartList?.findIndex((itm)=>itm.id==productId)!==-1)
                                    ? 
                                    <PiBagFill
                                        onClick={()=> dispatch(removeCart(productId))}
                                        style={{
                                            fill: 'black'
                                        }}
                                    />
                                    :
                                    <PiBagLight
                                        onClick={()=> (isLoggedIn)?dispatch(addCart({id:productId,size:details?.size?.[0] || ''})):navigate('/login?returnTo=categories/pret')}
                                    
                                    />
                            }
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProductCard