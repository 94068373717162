
import './page.module.css'
import './globals.css'
import {React} from 'react'
import Banner from './components/Banner/Banner';
import FeatureCategories from './components/FeaturedCat/FeatureCategories';
import FollowUs from './components/FollowUs/FollowUs';
import AppointBanner from './components/AppointBanner/AppointBanner';
import HeroBannerBig from '../assets/images/Desktop.jpg'
import HeroBannerSmall from '../assets/images/Mobile.jpg'
import RootLayout from './Layout';


export default function Home() {

  return (
    <RootLayout >
      <main className="">
        <Banner 
          // img={window.innerWidth > 650 ? HeroBannerBig : HeroBannerSmall}
          desktop={HeroBannerSmall}
          mobile={HeroBannerBig}
          subHeading="MAGICAL MOMENTS & PRICEOUS PIECES"
          btnTitle="SHOP NOW"
          bannerContainerAlign="flex-start"
          textDivWidth="40em"
        />
        <FeatureCategories />
        <AppointBanner 
          logoImg="/images/logo-bug-appointment@2x.png"
          logoImgWidth="160"
          logoImgHeight="160"
          title="BOOK AN APPOINTMENT"
          subTitle="We are available for online as well as in-store appointments"
          MenuList={[
            {href:"", menu:"Consultation"},
            {href:"", menu:"Customisation"},
            {href:"", menu:"Measurements"},
          ]}

          btnTitle="BOOK NOW"
        />

        <FollowUs />
      </main>
    </RootLayout>
  )
}
