import './Styles.css'
import RootLayout from "../Layout"


const PoliciesContainer = ({heading,children}) => {

    return <>
        <RootLayout>
        <div className="policies-container-section">
            <h1>{heading}</h1>
            <div className="policies-container-content">
                {children}
            </div>
        </div>
    </RootLayout>
    </>
}

export default PoliciesContainer