import { useNavigate } from "react-router-dom";
import RootLayout from "../Layout";
import { FieldContainer, Form, FormActions, Input } from "../commonComponent/Form";
import './style.css'
import { useState } from "react";
import CustomButton from "../commonComponent/Button/CustomButton";
import authService from "../../services/axios/auth";
import { btToast } from "../components/BtToast";

const UpdatePassword=()=>{

    const [payLoad, setPayload] = useState({});
    const [errors,setErrors]=useState(null)
    const [buttonLoading, setButtonLoading] = useState(false)
    const navigate = useNavigate();

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]:null
        })
    }

    async function handleUpdatePassword(){
        setButtonLoading(true)
        return authService?.updatePassword(payLoad)
            .then((resp) => {
                btToast({type:'success',message: resp?.data?.message})
                navigate('/profile')
            })
            .catch((err) => {
                btToast({message: err?.response?.data?.message})
                setErrors(err?.response?.data?.errors)
            })
            .finally(()=>setButtonLoading(false))
    }
    return <RootLayout>
        <Form heading='Update Password' style={{margin:'50px auto',maxWidth:'450px'}}>
            <FieldContainer>
                <Input 
                    label="Old Password" 
                    error={errors?.old_password}
                    value={payLoad?.old_password}
                    type="password"
                    name='old_password'
                    handleChange={handleChange}
                />
                <Input 
                    label="New Password" 
                    error={errors?.new_password}
                    value={payLoad?.new_password}
                    type="password"
                    name='new_password'
                    handleChange={handleChange}
                />
                <Input 
                    label="Confirm Password" 
                    error={errors?.confirm_password}
                    value={payLoad?.confirm_password}
                    type="password"
                    name='confirm_password'
                    handleChange={handleChange}
                />
            </FieldContainer>
            <FormActions>
                <CustomButton
                    loading={buttonLoading}
                    title='Update'
                    onClick={handleUpdatePassword}
                />
            </FormActions>
        </Form>
    </RootLayout>

}

export default UpdatePassword;