import defaultImg from './assets/default/product-400x500.png'
export const COLOR={
    primary: 'cyan',
    danger:'#a62117'
}

export const PRODUCT={
    ratio:1000/1500,
    paddingTop:(1500/1000)*100+`%`,
    width:1000,
    height:1500,
    defaultImg:defaultImg   
}

// export const PRODUCT={
//     ratio:700/1000,
//     paddingTop:(1000/700)*100+`%`,
//     width:700,
//     height:1000,
//     defaultImg:defaultImg   
// }