import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RootLayout from "../Layout";
import { FieldContainer, Form, FormActions, Input } from "../commonComponent/Form";
import './style.css'
import { useState } from "react";
// import { useDispatch } from "react-redux";
import CustomButton from "../commonComponent/Button/CustomButton";
import { useDispatch } from "react-redux";
import { getSignup, verifyEmail } from "../../store/authSlice";
import authService from "../../services/axios/auth";
import { btToast } from "../components/BtToast";

const VerifyEmail=()=>{
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [payLoad, setPayload] = useState({email: searchParams?.get('email')});
    const [loading, setLoading] = useState(false);
    const [errors,setErrors]=useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch(); 

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]:null
        })
    }

    const handleVerify=()=>{
        setLoading(true)
        authService?.verifyEmail(payLoad)
            .then((res)=>{
                if(res?.status === 200){
                    navigate('/personal-info', {state: {returnTo: location?.state?.returnTo}})
                }else{
                    setErrors(res?.response?.data?.errors)
                }
            })
            .catch((err)=>{
                btToast({message: err?.message})
            }).finally(()=>setLoading(false));
    }
    return <RootLayout>
        <Form heading='Verify Email' style={{margin:'50px auto',maxWidth:'450px'}}>
            <FieldContainer>
                <Input 
                    name="email"
                    label='Email'
                    disabled
                    value={payLoad?.email}
                    error={errors?.email}
                    handleChange={handleChange}
                />
                <Input 
                    name="otp"
                    label='Otp'
                    value={payLoad?.otp}
                    error={errors?.otp}
                    handleChange={handleChange}
                />
            </FieldContainer>
            <FormActions>
                <CustomButton
                    loading={loading}
                    title='Next'
                    onClick={handleVerify}
                />
            </FormActions>
        </Form>
    </RootLayout>

}

export default VerifyEmail