import React, { useEffect, useState } from 'react'
import './MyOrderDetail.css'
import RootLayout from '../../Layout'
import orderService from '../../../services/axios/order'
import { useParams } from 'react-router-dom'
import { useTextLimit } from '../../CommonFunction/TextLimit'
import { toCurrencyFormat } from '../../CommonFunction/CurrencyFormat'
import BackPageCompo from '../../commonComponent/BackPageCompo/BackPageCompo'
import { PRODUCT } from '../../../constants'
import DataError from '../../components/DataError'
import Loader from '../../components/Loader'


function MyOrderDetail() {

    const [orderDetail, setOrderDetail] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [loading, setLoading] = useState(false);

    const {uid} = useParams();
    useEffect(() => {
        setLoading(true)
        orderService.details({uid})
            .then((resp) => {
                setOrderDetail(resp?.data?.response)
                setImagePath(resp?.data?.response)
                setLoading(false)
            })
            .catch((err) => {
                setOrderDetail(null)
                setLoading(false)
            })
    },[])

    if(loading){
        return <RootLayout>
            <Loader paper fullContent/>
            </RootLayout>
    }
    if(!orderDetail){
        return <RootLayout>
            <DataError paper fullContent title={'Sorry, No order found.'}/>
            </RootLayout>
    }

  return (
    <RootLayout>
        <div className='myOrderDetailSection flex-centered flex-column gap-2 w-100'>
            <BackPageCompo 
                href="/profile"
                title="Order Details"
            />
                    <div className='flex gap-2'>
                    <div className='orderDetailImgContainer flex-column gap-2 w-100'>
                        <div className='flex-column gap-2 w-100'>
                            <div className='flex-column gap-1 w-100'>
                                <div className='orderDetailSummary flex-between gap-2 w-100'>
                                    <div className='orderDetailSummaryImg w-100'>
                                        <h2>Items Summary</h2>
                                    </div>
                                    {
                                        window.innerWidth >= 768 ? <>
                                            <div className=''>
                                                <h2>Price</h2>
                                            </div>
                                            {/* <div className='w-50'
                                                style={{
                                                    // textAlign: 'right'
                                                }}
                                            >
                                            </div> */}
                                        </>
                                        :''
                                        }
                                </div>
                                {orderDetail?.product_detail?.map((product,i) => {
                                    return <OrderDetailCard 
                                        img={imagePath?.thumb_path+product?.primary_image}
                                        productName={product?.name}
                                        quantity={product?.quantity}
                                        price={product?.sale_price}
                                    />
                                })}
                                
                            </div>
                            
                            <div className='orderedSummaryDetail flex-column gap-1'>
                                <div className='orderedSummaryDetailDiv flex-between flex-centered gap-2 w-100'>
                                    <div className='w-100'>
                                        <h2>Payment Summary</h2>
                                    </div>
                                    
                                </div>
                                <hr/>
                                <div className='flex-column gap-1'>
                                    <OrderSummaryCard 
                                        name="Subtotal"
                                        value={orderDetail?.sub_total && toCurrencyFormat(orderDetail?.sub_total)}
                                    />
                                    {/* <OrderSummaryCard 
                                        name="Tax"
                                        value={`+ ${toCurrencyFormat(orderDetail?.tax_total)}`}
                                    /> */}
                                    <OrderSummaryCard 
                                        name="Delivery Fee"
                                        value={toCurrencyFormat("0")}
                                    />
                                    <hr/>
                                    <div className='orderDetailGrandTotalSection flex-column gap-1'>
                                        <div className='orderDetailGrandTotal flex-between gap-2 w-100'>
                                            <div className=''>
                                                <h2>Total</h2>
                                            </div>
                                            <div className=''>
                                                <h2>{orderDetail?.grand_total && toCurrencyFormat(orderDetail?.grand_total)}</h2>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className='orderedSummaryDetail flex-column gap-1'>
                                <div className='orderedSummaryDetailDiv flex-between flex-centered gap-2 w-100'>
                                    <div className='w-100'>
                                        <h2>Order Summary</h2>
                                    </div>
                                    
                                </div>
                                <hr/>
                                <div className='flex-column gap-1'>
                                    <OrderSummaryCard 
                                        name="Order Time"
                                        value={orderDetail?.order_at}
                                    />
                                    <OrderSummaryBoldCard 
                                        name="Payment Status"
                                        value={orderDetail?.payment_status}
                                    />
                                    {orderDetail?.payment_status === "SUCCESS" && <OrderSummaryCard 
                                        name="Payment Done on"
                                        value={orderDetail?.paid_on}
                                    />}
                                    {/* <OrderSummaryCard 
                                        name='Mode of Payment'
                                        value='UPI - lucky@okhedfc.ybl'
                                    /> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
    
    
    
    
    
                    <div className='orderDetailTxtDiv flex-column gap-2'>
                        <div className='orderDetailTrackOrder flex-column gap-1'>
                            <h2>Track Order</h2>
                            <hr/>
                            <div className='flex-between flex-centered'>
                                <p>Status</p>
    
                                <p>{orderDetail?.tracking_status_name}</p>
                            </div>
                            <div className='flex-between flex-centered'>
                                <p>Expected Delivery on </p>
    
                                <p>{orderDetail?.tracking_expected_date !== '0000-00-00 00:00:00' && orderDetail?.tracking_expected_date}</p>
                            </div>
                            <div className='flex-between flex-centered'>
                                <p>Tracking Location</p>
    
                                <p>{orderDetail?.tracking_location}</p>
                            </div>
                        </div>
    
    
                        <div className='orderDetailSummarySection flex-column gap-1'>
                                <div className='orderDetailSummary flex-between gap-2 w-100'>
                                    <div className='w-100'>
                                        <h2>Customer And Order Details</h2>
                                    </div>
                                </div>
                                <hr/>
                                <div className='flex-column'>
                                    <CustomerDetailCard 
                                        name='Customer Name'
                                        value={orderDetail?.customer_detail?.name}
                                    />
                                    <CustomerDetailCard 
                                        name='Phone Number'
                                        value={orderDetail?.customer_detail?.mobile}
                                    />
                                    <CustomerDetailCard 
                                        name='Email'
                                        value={orderDetail?.customer_detail?.email}
                                    />
                                    
                                </div>
                                
                            </div>
    
                        
                        <div className='deliveryAddressSection flex-column gap-1'>
                            <div className='deliveryAddressDiv flex-between flex-centered gap-2 w-100'>
                                <div className='w-100'>
                                    <h2>Delivery Address</h2>
                                </div>
                            </div>
                            <hr/>
                            <div className='flex-column gap-1'>
                                <DeliveryAddressCard 
                                    name="Address line"
                                    value={orderDetail?.customer_detail?.address1}
                                />
                                <DeliveryAddressCard 
                                    name="Flat / Building"
                                    value={orderDetail?.customer_detail?.address2}
                                />
                                <DeliveryAddressCard 
                                    name="City, State"
                                    value={`${orderDetail?.customer_detail?.city},  ${orderDetail?.customer_detail?.state}`}
                                />
                                <DeliveryAddressCard 
                                    name="Postcode"
                                    value={`${orderDetail?.customer_detail?.zipcode}, ${orderDetail?.customer_detail?.country}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                

            
        </div>
    </RootLayout>
  )
}

export default MyOrderDetail


const OrderDetailCard = (props) => {

    const [imgSrc, setImgSrc] = useState(props?.img)
    return (
        <>
        <hr/>
        <br/>
        <div className="orderDetailCardSection flex-between w-100 gap-2">
            <div className='orderDetailCardImg w-100 flex gap-1'>
                <div className='orderDetailCardImgAbs'>
                    <img src={imgSrc} onError={()=>setImgSrc(PRODUCT.defaultImg)} />
                </div>
                <h3>{useTextLimit(props.productName, 50)}</h3>
            </div>
            {
                window.innerWidth >= 768 ? <>
                        <div className='price'>
                            <p>{toCurrencyFormat(props.price)}</p>
                        </div>
                    </>
                : ''
            }
        </div>

        </>
    )
}


const CustomerDetailCard = (props) => {

    return (
        <>
            <div className="customerDetailCardSection flex-between w-100 gap-2">
                <div className='w-100 flex gap-1'>
                    <h3>{props.name}</h3>
                </div>
                <div className='w-100'
                    style={{
                        // textAlign: 'right',
                    }}
                >
                    <p>{props.value}</p>
                </div>
            </div>
        </>
    )
}

const OrderSummaryBoldCard = (props) => {

    return(<>
        <div className='orderSummaryCardSection flex-between'>
            <div className=''>
                <h3>{props.name}</h3>
            </div>
            <div className='' style={{fontWeight:'500'}}>
                <p>{props.value}</p>
            </div>
        </div>
    </>)
}

const OrderSummaryCard = (props) => {

    return(<>
        <div className='orderSummaryCardSection flex-between'>
            <div className=''>
                <h3>{props.name}</h3>
            </div>
            <div className='' style={{}}>
                <p>{props.value}</p>
            </div>
        </div>
    </>)
}


const DeliveryAddressCard = (props) => {

    return(<>
        <div className='deliveryAddressCardSection flex gap-1'>
            <div className=''>
                <h3>{props.name}</h3>
            </div>
            {
                window.innerWidth <= 380 ? ''
                :
                    <div className=''>
                        <p>:</p>
                    </div>
            }
            <div className=''>
                <p>{props.value}</p>
            </div>
        </div>
    </>)
}