import './Button.css';

function Button(props) {

    return (
        <div className="buttonSection">
            <button 
                className={`btn ${props.variant}`} 
                style={props.btnClass} 
                onClick={props.onClick}>{props?.buttonLoading ? <span style={{color: 'lightgrey'}}>Loading...</span> : props.title}</button>
        </div>
    )
}

export default Button
Button.defaultProps={
    variant:'btn-darkFill',
    title:'Click'
}