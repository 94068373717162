import Card from '../../commonComponent/Card/Card'
import { useEffect, useState } from 'react'
import './FeatureCategories.css'

import MenImg from '../../../assets/images/categories-men@2x.jpg'


const FeatureCatHead = (props)=> {

    return(
        <div className="fc-content flex-column flex-centered w-50" >
            <h2 className="heading-txt">{props.title}</h2>
            <p className="p-txt">{props.subTitle}</p>
        </div>
    )
}

function FeatureCategories() {

    const [imgWidth, setImgWidth] = useState(0)
    const [imgHeight, setImgHeight] = useState(0)

    useEffect(()=>{
        setImgWidth(window.innerWidth < 500 ? "100%" :'100%');
        setImgHeight(window.innerWidth < 500 ? "100%" :'100%');
    },[])

    return (
        <div className="fc-section flex-centered flex-column w-100">
            <FeatureCatHead 
                title="FEATURED CATEGORIES"
                subTitle="Welcome to the world of Akriti By Shakun and celebrate luxury with our carefully curated collations."
            />

            <div className="w-80 fc-cards-div flex-centered flex gap-2">
                <Card 
                    // maxWidth={window.innerWidth >= 1200 ? cardMaxWidth :  window.innerWidth >= 600 ? '20em' : '100%'}
                    // imgMaxHeight={window.innerWidth >= 1200 ? cardMaxHeight :  window.innerWidth >=768 ? '25em' : 'auto'}

                    src="/images/categories-pret@2x.png"
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}

                    title="PRET"
                    desc="Classic silhouettes made for the new season."
                    btnTitle="SHOP NOW"
                    href="/categories/pret"
                />
                <Card 
                    // maxWidth={window.innerWidth >= 1200 ? cardMaxWidth :  window.innerWidth >= 600 ? '20em' : '100%'}
                    // imgMaxHeight={window.innerWidth >= 1200 ? cardMaxHeight :  window.innerWidth >=768 ? '25em' : 'auto'}

                    src="/images/categories-bridal@2x.png"
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}

                    title="BRIDAL"
                    desc="Classic silhouettes made for the new season."
                    btnTitle="SHOP NOW"
                    href="/categories/bridal"
                />
                <Card 
                    // maxWidth={window.innerWidth >= 1200 ? cardMaxWidth :  window.innerWidth >= 600 ? '20em' : '100%'}
                    // imgMaxHeight={window.innerWidth >= 1200 ? cardMaxHeight :  window.innerWidth >=768 ? '25em' : 'auto'}
                    src={MenImg}
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}

                    title="MEN"
                    desc="Classic silhouettes made for the new season."
                    btnTitle="SHOP NOW"
                    href="/categories/men"
                />
            </div>
        </div>
    )
}

export default FeatureCategories