import { useEffect, useMemo, useState } from 'react'
import './Banner.css'
import bannerService from '../../../services/axios/banner'
import { Carousel } from 'react-responsive-carousel'

function Banner() {

    const [alllist, setAllList] = useState([])
    const [path,setPath] = useState(null)
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(()=>{
        bannerService?.bannerList()
            .then((result) => {
                setAllList(result?.data?.response?.list)
                setPath(result?.data?.response)
            }).catch((err) => {
            });
    },[])

    const bannerData = useMemo(()=>{
        if(width < 650 ){
            return { device:'MOBILE', list :alllist?.filter(bnr => bnr?.type == "MOBILE")}
        }else{
            return { device:'DESKTOP', list :alllist?.filter(bnr => bnr?.type == "DESKTOP")}
        }
    },[width,alllist])

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
    
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // const bannerContainer = {
    //     justifyContent: props.bannerContainerAlign,
    // }
    
    // const imageDiv = {
    //     filter: props.blur,
    // }
    return (
        <Carousel 
            autoPlay={true} 
            showArrows={true} 
            infiniteLoop={true} 
            interval={5000} 
            selectedItem={1} 
            emulateTouch={false}
            autoFocus={true}
            showThumbs={false}
            >
            {bannerData?.list?.map((itm,index) =>{
                return<>
                    {/* <p>{itm?.image}</p> */}
                    <img 
                        src={path?.banner_image_path + itm?.image}  
                        width="100%" 
                        height="100%" 
                        alt="Image"
                    />    
                </>
            })}
        </Carousel>
    )
}

export default Banner
