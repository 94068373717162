import React, { useEffect, useState } from 'react'
import RootLayout from '../../Layout'
import './PretProduct.css'

import { useDispatch } from 'react-redux';
import { getFavoriteParoducts} from '../../../store/productSlice';
import ProductCard from '../../commonComponent/Product/Product';
import productService from '../../../services/axios/product';
import Loader from '../../components/Loader';
import DataError from '../../components/DataError';

function PretProduct() {

    const dispatch = useDispatch();

    const [productList, setProductList] = useState({});
    const [loading, setLoading] = useState(false);


    // useEffect(() => {
    //     dispatch(getFavoriteParoducts({}))
    // }, [])

    useEffect(() => {
        setLoading(true)
        productService.list({category:'Pret',pageSize:100})
            .then((resp) => {
                setProductList(resp?.data?.response);
                setLoading(false)
            })
            .catch(err=>{
                setLoading(false)
            })
    }, [])
    if(loading){
        return <RootLayout>
            <Loader paper fullContent/>
        </RootLayout>
    }

    if(productList?.length===0){
        return <RootLayout>
            <DataError paper fullContent title="No product found for this category. Please try another one."/>
        </RootLayout>
    } 
    
    return (
        <RootLayout >
            <div className="pretProductSection w-100 flex-centered flex-column gap-1">
                <div className="pretProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <div className="pretProductHeading">
                        <p>CATEGORIES / PRET </p>
                        <h2>Shop All</h2>
                    </div>
                    {/* <div className="pretProductHeaderBottom flex flex-between">
                        <div className="pretProductFilter">
                            <div className='pretProductFilterTxt flex'>
                                <p>FILTERS </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Filter 1</NavLink></li>
                                <li><NavLink>Filter 2</NavLink></li>
                                <li><NavLink>Filter 3</NavLink></li>
                                <li><NavLink>Filter 4</NavLink></li>
                            </ul>
                        </div>
                        <div className="pretProductSortBy">
                            <div className='pretProductSortByTxt flex'>
                                <p>SORT BY </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Sort By 1</NavLink></li>
                                <li><NavLink>Sort By 2</NavLink></li>
                                <li><NavLink>Sort By 3</NavLink></li>
                                <li><NavLink>Sort By 4</NavLink></li>
                            </ul>
                            <div className="pretProductGrid">

                            </div>
                        </div>

                    </div> */}
                </div>
                <div className="pretProductContent flex-centered flex-column gap-3">

                    <div className='flex-centered w-100'>
                        {productList?.list?.map((product) => <ProductCard 
                                        details={product}
                                        path={productList?.thumb_path}
                                        key={product?.id} 
                                    />)
                        }
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>

            </div>
        </RootLayout>

    )
}

export default PretProduct