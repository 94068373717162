
import './style.css'
export const FormError=(props)=>{
    return <div style={{textAlign:'center',color:'red',fontWeight:'bold'}}>{props?.error}</div>
}


export const Form=(props)=>{
    return <>
    <div className="bt-form" style={props?.style}>
    <p className='bt-form-heading'>{props?.heading}</p>
    {props.children}
    </div>
    </>
}

export const FieldContainer=(props)=>{
    return <>
    <div className="bt-field-container">
        {props.children}
    </div>
    </>
}

export const Input=(props)=>{
    return <div className='bt-form-input-wrapper'>
    <label>{props?.label}</label>
    <input 
    className='bt-input' 
    type={props.type}
    placeholder={props.placeholder}
    disabled={props.disabled}
    name={props.name}
    value={props.value}
    onChange={(e) =>props.handleChange(props.name, e?.target?.value)}
    />
    {props?.error ? <p className='bt-input-error'>{props?.error}</p>:null}
    </div>
}

Input.defaultProps={
    type:'text',
    placeholder:'',
    handleChange:()=>null
}


export const FormActions=(props)=>{
    return <div className='bt-form-actions'>
        {props.children}
    </div>
}
