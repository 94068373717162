import React, { Children } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import Cart from '../app/Cart/Cart'
import Favorite from '../app/Favorite/Favorite'
import MyOrderDetail from '../app/MyProfile/MyOrderDetail/MyOrderDetail'
import MyProfile from '../app/MyProfile/MyProfile'
import NewArrivals from '../app/NewArrivals/NewArrivals'
import Page from '../app/Page'
import BridalProduct from '../app/ProductCategories/Bridal/BridalProduct'
import MenProduct from '../app/ProductCategories/Men/MenProduct'
import PretProduct from '../app/ProductCategories/Pret/PretProduct'
import ProductDetail from '../app/ProductDetail/ProductDetail'
import CartFailed from '../app/Cart/CartFailed'
import CartSuccess from '../app/Cart/CartSuccess'
import CartRespHandler from '../app/Cart/CartRespHandler'
import ComingSoon from '../app/ComingSoon'
import CustDetailForm from '../app/CustDetailForm/Index'
import TermAndCondition from '../app/Policies/TermAndConditions/Index'
import PrivacyPolicy from '../app/Policies/PrivacyPolicy/Index'
import ReturnPolicy from '../app/Policies/ReturnPolicy/Index'
import Policies from '../app/Policies/Index'
import Login from '../app/Auth/Login'
import SingUp from '../app/Auth/SignUp'
import ContactUs from '../app/ContactUs/Index'
import ForgotPassword from '../app/Auth/ForgotPassword'
import VerifyForgotOtp from '../app/Auth/VerifyForgotOtp'
import UpdatePassword from '../app/Auth/UpdatePassword'
import Designer from '../app/Policies/Designer'
import VerifyEmail from '../app/Auth/VerifyEmail'



const AllRouters = () => {
    const router = [
        {path: '/', element: <Page />},

        {path: '/login', element: <Login />},
        {path: '/sign-up',element:<SingUp/>,
          children: [
            {path: 'personal-info', element: <CustDetailForm />},
          ]
        },
        {path: '/forgot-password', element: <ForgotPassword />},
        {path: '/verify-forgot-otp', element: <VerifyForgotOtp />},
        {path: '/verify-email', element: <VerifyEmail />},
        {path: '/update-password', element: <UpdatePassword />},
        
        {path: '/profile/', element: <MyProfile />},
        {path: '/my-profile/my-order-detail/:uid', element: <MyOrderDetail />},
        

        {path: '/new-arrivals', element: <NewArrivals />,
          children: [
            
          ]
        },
        {path: '/product-detail/:id', element: <ProductDetail />},
        {path: '/policies', element: <Policies />,
          children: [
            {path: "", element: <TermAndCondition />},
            {path: 'privacy-policy', element: <PrivacyPolicy />},
            {path: 'return-policy', element: <ReturnPolicy />},
          ]
        },
        {path: '/term-and-condition', element: <TermAndCondition />},
        {path: '/privacy-policy', element: <PrivacyPolicy />},
        {path: '/return-policy', element: <ReturnPolicy />},
        {path: '/contact-us', element: <ContactUs />},

        {path: '/sign-up/personal-info', element: <CustDetailForm />},
        {path: '/personal-info', element: <CustDetailForm />},
        {path: '/categories/pret', element: <PretProduct />},
        {path: '/categories/bridal', element: <BridalProduct />},
        {path: '/categories/men', element: <MenProduct />},
        {path: '/cart', element: <Cart />},
        {path: '/cart/failed', element: <CartFailed />},
        {path: '/cart/success', element: <CartSuccess />},
        {path: '/cart/resp-handler', element: <CartRespHandler />},
        {path: '/favorite', element: <Favorite />},
        {path: '/designer', element: <Designer />},
        {path: '/coming-soon', element: <ComingSoon />},
        
    ]

    return useRoutes(router)
}

function MainRouter() {
  return (
    <div >
        <BrowserRouter basename='/'>

            <AllRouters />
        </BrowserRouter>
    </div>
  )
}

export default MainRouter