import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteParoducts } from '../../store/productSlice';
import ProductCard from '../commonComponent/Product/Product';
import RootLayout from '../Layout'
import './Favorite.css'

function Favorite() {
    const [imagePath, setImagePath] = useState(null);
    const { favoritesList } = useSelector(store => store.product);
    const dispatch = useDispatch();

    const [favoriteProductList, setFavoriteProductList] = useState([]);

    useEffect(() => {
        
        dispatch(getFavoriteParoducts())
        .then((resp) => {
            setFavoriteProductList(resp?.response?.list)
            setImagePath(resp?.response)

        })
        .catch((err) => {
        })
    }, [])
    
  return (
    <RootLayout>
        <div className="pretProductSection w-100 flex-centered flex-column gap-1">
                <div className="pretProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <div className="pretProductHeading">
                        <p>CATEGORIES / Favorites /</p>
                        <h2>Shop All</h2>
                    </div>
                    {/* <div className="pretProductHeaderBottom flex flex-between">
                        <div className="pretProductFilter">
                            <div className='pretProductFilterTxt flex'>
                                <p>FILTERS </p>
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Filter 1</NavLink></li>
                                <li><NavLink>Filter 2</NavLink></li>
                                <li><NavLink>Filter 3</NavLink></li>
                                <li><NavLink>Filter 4</NavLink></li>
                            </ul>
                        </div>
                        <div className="pretProductSortBy">
                            <div className='pretProductSortByTxt flex'>
                                <p>SORT BY </p>
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Sort By 1</NavLink></li>
                                <li><NavLink>Sort By 2</NavLink></li>
                                <li><NavLink>Sort By 3</NavLink></li>
                                <li><NavLink>Sort By 4</NavLink></li>
                            </ul>
                            <div className="pretProductGrid">

                            </div>
                        </div>

                    </div> */}
                </div>
                {/* <br/> */}
                {/* <br/> */}
                <div className="pretProductContent flex-centered flex-column gap-3">

                    <div className='flex-centered w-100'>

                        {favoriteProductList?.map((product, i) => {
                            return (<>
                                <ProductCard
                                    details={product}
                                    path={imagePath?.image_path}
                                    key={product?.id} 
                                />
                            </>)
                        })}
                    </div>
                    <br/>
                </div>
            </div>
    </RootLayout>
  )
}

export default Favorite