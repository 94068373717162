import { createSlice } from "@reduxjs/toolkit";

import productService from '../services/axios/product/index'

  export const getFavoriteParoducts = () => async () => {
    return productService
      .favoriteList().then(async (res) => {
        return res?.data;
      })
      .catch((error) => {
        return error;
      });
  };
  export const getProduct = (param) => async () => {
  
    return productService
      .list(param)
      .then(async (res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  };

  export const addFavorite = (id) => async (dispatch) => {
    dispatch(addFavoriteAction(id))
    productService?.addFavorite({id})
      .then((res)=>{
        
        return res
      })
      .catch((err)=>{
        dispatch(removeFavoriteAction(id))
        return err
      })
  };

  export const removeFavorite = (id) => async (dispatch) => {
    dispatch(removeFavoriteAction(id))
    productService?.removeFavorite({id})
      .then((res)=>{
        
        return res
      })
      .catch((err)=>{
        dispatch(addFavoriteAction(id))
        return err
      })
  };


const initialState = {
    list: [],
    favoritesList:[]
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
      addFavoriteAction:(state,action)=>{
          state.favoritesList=[...state.favoritesList, action.payload]
      },
      removeFavoriteAction:(state,action)=>{
          state.favoritesList=state.favoritesList.filter((itm)=>itm!=action.payload)
      },
      setFavoriteList: (state,action)=>{
        state.favoritesList=action.payload
      },
      setList: (state, action) => {
        state.list = action.payload;
      },
    },
  });


export const { setList, addFavoriteAction, removeFavoriteAction, setFavoriteList } = productSlice.actions;

export default productSlice.reducer;