
import { useState,useEffect } from 'react'
import './style.css'
export const BtToast=()=>{

const [data,setData]=useState()

const handleBtToast=(data)=>{

    setData(data?.detail)
    setTimeout(() => {
        setData(null);
      }, 1500);
}

useEffect(() => {
    window.addEventListener("openBtToast", (data)=>{
        handleBtToast(data)
    });
    return () => {
      window.removeEventListener("openBtToast", () => {
        setData(null)
      });
    };
  }, [handleBtToast]);



if(!data){
    return false
}
    return <>
        <div className={`bt-toast type-${data?.type || 'error'}`} onClick={()=>setData(null)}>
            {/* {data?.title?<p style={{fontSize:'1.2em',fontWeight:'400',color:'white'}}>{data?.title}</p>:null} */}
            <p style={{fontSize:'1em',color:'white'}}>{data?.message}</p>
        </div>
    </>
}



export const btToast=(data)=>{
    const event = new CustomEvent('openBtToast', { detail: data });
  window.dispatchEvent(event);
}