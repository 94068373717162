import { createSlice } from "@reduxjs/toolkit";
import customerService from "../services/axios/customer";


export const getDetails = () => async (dispatch) => {

    return customerService
        .getDetails()
        .then((resp) => {
            dispatch(setProfile(resp?.data?.response?.details))
            return resp?.data;

        })
        .catch((err) => {
            // ("customer Error: ",err);
        })
}

const initialState = {
  profile:null
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setProfile
} = customerSlice.actions;

export default customerSlice.reducer;
