import { Paper } from "../BTUI"
import { Icloader } from "../Icons"
import { Watch } from 'react-loader-spinner'

const Loader=(props)=>{

if(props?.paper){
return <Paper contentCenter style={{
        flexDirection:'column',
        rowGap:'15px',
        minHeight:(props?.fullContent)?'50vh':props?.paperHeight,    
        ...props.paperStyle
        }}>
        {/* <Icloader style={{fontSize:'3em'}} {...props}/> */}
        <Watch
  visible={true}
  height={props.size}
  width={props.size}
  radius="48"
  color={props.color}
  ariaLabel="watch-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
        <p>Loading. Please wait.</p>
    </Paper>
}

    return <Watch
    visible={true}
    height={props.size}
    width={props.size}
    radius="48"
    color={props.color}
    ariaLabel="watch-loading"
    wrapperStyle={{}}
    wrapperClass=""
    />
}
Loader.defaultProps={
    size:'50px',
    color:'grey',
    paperStyle:{},
    paperHeight:'100px'
}


export default Loader