import { Paper } from "../BTUI"
import { IcErrorSquare } from "../Icons"


 const DataError=(props)=>{


if(props?.paper){
    return <Paper contentCenter style={{
        flexDirection:'column',
        rowGap:'15px',
        minHeight:(props?.fullContent)?'50vh':props?.paperHeight,    
        ...props.paperStyle
        }}>
        {/* <Icloader style={{fontSize:'3em'}} {...props}/> */}
        <IcErrorSquare style={{color:'red',fontSize:'3em'}}/>
        <p>{props?.title}</p>
    </Paper>
}

    return <div>
        <IcErrorSquare style={{color:'red',fontSize:'3em'}}/>
        <p>{props?.title}</p>
        </div>
}

export default DataError

DataError.defaultProps={
    size:'50px',
    color:'grey',
    title:'No Records Found',
    paperStyle:{},
    paperHeight:'100px'
}


