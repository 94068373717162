import PoliciesContainer from '../Index'
import './Style.css'



const ReturnPolicy = () => {

    return <>
        <PoliciesContainer
            heading="Refund & Exchange"
        >
            <p>Our garments are all on a made to order basis specially handcrafted for you. We assume that you agree to our terms and conditions when placing an order. Once an order is placed, we do not offer any exchange or returns. However, in case of alterations, we will be happy to assist you.</p>
        </PoliciesContainer>
    </>
}

export default ReturnPolicy