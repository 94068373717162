import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartList: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addCart: (state, action) => {
      // ("Add Cart", action.payload);
      state.cartList = [...state.cartList, action.payload];
    },
    removeCart: (state, action) => {
      // ("removing", action.payload);
      state.cartList = state.cartList.filter((itm) => itm.id != action.payload);
    },
    
    updateCartProductInfo: (state, action) => {
      // ("update cart product info", action.payload);
      state.cartList=state.cartList.map((obj,i)=>{
        if(action.payload?.id==obj?.id){
          obj[action?.payload?.key]=action?.payload?.value
        }
        return obj
      })
      state.cartList = state.cartList.filter((itm) => itm.id != action.payload);
    },
    clearCart: (state, action) => {
      state.cartList = [];
    },
  },
  extraReducers: {},
});

export const { addCart, removeCart, clearCart ,updateCartProductInfo} = orderSlice.actions;

export default orderSlice.reducer;
