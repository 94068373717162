import RootLayout from '../Layout'
import './style.css'
import ContactImg from '../../assets/images/heroBannerSmall.jpg'
import { FaLocationDot } from "react-icons/fa6";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const ContactUs = () => {

    return<RootLayout>
        <h1>Contact Us</h1>
        <div className="contact-us-section">
            {/* <div className="contact-us-img-container">
                <div className="contact-us-abs-div">
                    <img src={ContactImg} />
                </div>
            </div> */}
            <div className="contact-us-txt-container">
                <ContactCard 
                    icon={<FaLocationDot />}
                    title={"Address"}
                >
                    FlAGSHIP STORE<br/>
                    29, Kesar Bagh, Opp. Sheesh Mahal, <br/>
                    Patiala - India
                </ContactCard>

                <ContactCard 
                    icon={<IoPhonePortraitOutline />}
                    title={"NUMBER "}
                >
                    +919888224142
                </ContactCard>

                <ContactCard 
                    icon={<MdEmail />}
                    title={"EMAIL "}
                >
                    akritibyshakun@gmail.com
                </ContactCard>

                <ContactCard 
                    icon={<FaFacebookF />}
                    title={"FACEBOOK "}
                >
                    Akriti by Shakun
                </ContactCard>

                <ContactCard 
                    icon={<FaInstagram />}
                    title={"INSTAGRAM "}
                >
                    Akriti by Shakun
                </ContactCard>
            </div>
            <div className="contact-us-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1722.2339760930709!2d76.3974427!3d30.309204699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39102946814a5d85%3A0x1a6abc9fe60223a7!2sAkriti%20by%20Shakun!5e0!3m2!1sen!2sin!4v1710581345126!5m2!1sen!2sin" width="100%" height="450" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <br/>
        <br/>
    </RootLayout>
}

export default ContactUs

const ContactCard = ({icon, title, children}) => {

    return<div className="contact-us-txt-div">
    <div className="contact-us-icon">
        {icon}
    </div>
    <div className="contact-us-txt">
        <h4>{title}</h4>
        <p>{children}</p>
    </div>
</div>
}