import { getRequest, postRequest } from '..';

/**
 * Api call
 */
class bannerService {

    bannerList = async () => {
        return await getRequest(`customer/banner/list`);
    }

}
const bannerServices = new bannerService();

export default bannerServices;