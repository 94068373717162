
import './App.css';
import { BtConfirm } from './app/components/BTUI/BtAlert';
import { BtToast } from './app/components/BtToast';
import MainRouter from './router';
import "react-responsive-carousel/lib/styles/carousel.min.css";


function App() {
  return (
    <div className="App">
      <BtConfirm />
      <BtToast/>
      <MainRouter />
    </div>
  );
}

export default App;
