import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from './../commonComponent/Button/Button';
import RootLayout from './../Layout';
import './CartFailed.css'

import {RxCross1} from "react-icons/rx";
import { useDispatch } from 'react-redux';
import { addCart, clearCart } from '../../store/orderSlice';
import CartSuccess from './CartSuccess';
import CartFailed from './CartFailed';
import orderService from '../../services/axios/order';


function CartRespHandler() {

    const [searchParams, setSearchParams] = useSearchParams();
    const uid=searchParams.get("uid") || 0

    const [detail,setDetail]=useState(null)
    const [status,setStatus]=useState(false)
    const navigate = useNavigate();
    const dispatch=useDispatch()


    //--fetch response
useEffect(()=>{
    orderService.details({uid})
    .then(resp=>{
        setDetail(resp?.data)
        dispatch(clearCart())
    })
    .catch(err=>{
        setDetail({status:false})
    })
},[uid])

if(detail==null){
    return <h1>Please wait. Processing....</h1>
}else{

    if(detail?.response?.payment_status=="SUCCESS"){
        return <CartSuccess 
        detail={detail?.response}
    />
    }else{
        return <CartFailed 
        detail={detail?.response}
        />
    }

}

}

export default CartRespHandler


