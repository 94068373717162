import React, { useEffect, useState } from "react";
import RootLayout from "../Layout";
import "./ProductDetail.css";
import Button from "../commonComponent/Button/Button";
import { useParams } from "react-router-dom";
import { BsCaretRightFill } from "@react-icons/all-files/bs/BsCaretRightFill.esm";
import { BsCaretDownFill } from "@react-icons/all-files/bs/BsCaretDownFill.esm";
import ProductCard from "../commonComponent/Product/Product";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  getProduct,
  removeFavorite,
} from "../../store/productSlice";
import productService from "../../services/axios/product";
import {useNavigate} from "react-router-dom"

import { FiHeart } from "@react-icons/all-files/fi/FiHeart.esm";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart.esm";
import { toCurrencyFormat } from "../CommonFunction/CurrencyFormat";
import { useTextLimit } from "../CommonFunction/TextLimit";
import BackPageCompo from "../commonComponent/BackPageCompo/BackPageCompo";
import { removeCart, addCart, updateCartProductInfo } from "../../store/orderSlice";
import { CgClose } from "@react-icons/all-files/cg/CgClose.esm";

// ======================Slider======================================
import 'react-awesome-slider/dist/styles.css';
import Modal from "../commonComponent/Modal";
import { PRODUCT } from "../../constants";
import Loader from "../components/Loader";
import DataError from "../components/DataError";
import { Carousel } from "react-responsive-carousel";
import { btToast } from "../components/BtToast";
// ======================Slider======================================





function ProductDetail() {
  const [product, setProduct] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [loading,setLoading]=useState(true)
  const [productList, setProductList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const { favoritesList} = useSelector(store => store.product);
  const { cartList } = useSelector((store) => store.order);
  const [isInCart, setIsInCart] = useState(false);
  const { id } = useParams();
  const { isLoggedIn } = useSelector(store => store.auth);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const productId = parseInt(id)

  useEffect(() => {
    setLoading(true)
    productService.details({id})
      .then((res) => {
        console.log("list",res)
        setProduct(res?.data?.response?.details);
        setImagePath(res?.data?.response)
        if (res?.data?.response?.details?.category)
          dispatch(
            getProduct({
              category: res?.data?.response?.details?.category,
              pageSize: 4,
            })
          ).then((resp) => {
            setProductList(resp?.response?.list);
            
          });
          setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setProduct(null)
        ("error on Product Details slice", error.request);
      });

      console.log("Product list: ", product)

    const element = document.getElementById("product");
    element?.scrollIntoView();

    setSelectedSize(null)
  }, [id]);

  useEffect(() => {
    let inCartDetail=cartList?.find((itm)=>itm.id==id);
    if(inCartDetail!==undefined){
      setSelectedSize(inCartDetail?.size)
      setIsInCart(true)
    }else{
      setIsInCart(false)
    }
    // setIsInCart(cartList?.findIndex((itm) => itm.id == id) !== -1);
  }, [cartList,id]);

  const handleSizeChange=(newSize)=>{
    //---if product is not in cart just change the selected size
    //---if product is in cart then change the size of product in cart, it will also change the change selected size as there is a useEffect on cartList change
    if(isInCart){
    dispatch(updateCartProductInfo({
      id:id,
      key:'size',
      value:newSize
    }))
    }else{
      setSelectedSize(newSize)
    }
  }

  useEffect(() => {}, [cartList]);

  if(loading){
    return <RootLayout>
      <Loader paper fullContent/>
    </RootLayout>
  }

  if(!product){
    return <RootLayout>
      <DataError paper fullContent title='Sorry, No product found. Please try another one.'/>
    </RootLayout>
  }
  return (
    <>
      {/* =====================================Popup Window================================================= */}
      <ModalComponent
        toggle={toggle}
        handleToggle={() => setToggle((prev) => !prev)}
      >
        <Carousel>
          {product?.images?.map((img, index) => {
              return(
                <div className="popupImgAbs flex-centered">
                  <img src={imagePath?.image_path+img?.path} key={index} />
                </div>
          )})}
        </Carousel>
      </ModalComponent>

      <Modal open={modal} onClose={() => setModal((prev) => !prev)}>
        <div className="sizeModal flex-column gap-1">
          <h2>BODY MEASUREMENT (INCHES)</h2>
            <div className="">
              <MeasurementCompo/>
            </div>
        </div>
      </Modal>

      {/* =====================================Popup Window================================================= */}

      <RootLayout>
        <hr
          style={{
            marginTop: "0.5em",
            marginBottom: "4em",
          }}
        />

        <div
          className="productDetailSection w-100 flex-centered flex-column gap-2"
          id="product"
        >
          <div className="w-80">
            <BackPageCompo
              href="/categories/pret"
              title={`CATEGORIES / ${product?.category} / ${product?.product_name}`}
            />
          </div>
          <div className="productDetailDiv flex gap-2">
            <div className="productDetailImgContainer flex-column gap-1">
              <div className=" gap-2">
                {product?.images.slice(0, 4).map((img, i) => {
                  return (
                    <>
                      <ProductImgSection key={i} img={imagePath?.image_path+img?.path} />
                    </>
                  );
                })}
              </div>
            </div>

            <div className="productTextContentSection flex-column gap-1">
              <h4>
                {product?.is_new_arrival ? "New Arrival | " : null}{" "}
                {product?.category}
              </h4>

              <div className="productTextContentWishlistIcon">
                <div className="productWishlistIconBorder flex-centered">
                {
                  favoritesList?.includes(productId)
                      ? 
                      <FaHeart
                          onClick={()=> (isLoggedIn)?dispatch(removeFavorite(productId)):navigate(`/login?returnTo=product-detail/${productId}`)}
                          style={{
                              fill: 'red'
                          }}
                      />
                      :
                      <FiHeart

                          onClick={()=> (isLoggedIn)?dispatch(addFavorite(productId)):navigate(`/login?returnTo=product-detail/${productId}`)}
                          style={{
                              width: '100%',
                              // height: '100',
                          }}
                      />
                  }
                </div>
              </div>

              <p className="productTextDesc">{product?.product_name}</p>

              <div className="productTextPriceDiv">
                <h2>{toCurrencyFormat(product?.sale_price)}</h2>
              </div>

              <div className="flex w-100">
                <hr />
              </div>

              <div className="productSizeDiv flex-column">
                <p>SELECT SIZE</p>

                <div className="productSize flex gap-2">
                  {product?.size?.map((size, i) => {
                    return (
                      <div
                        className={`sizeTab ${
                          selectedSize == size ? "active" : ""
                        }`}
                        onClick={() => handleSizeChange(size)}
                      >
                        {size}
                      </div>
                    );
                  })}
                </div>

                <p className="">
                  Size Guide
                  <BsCaretRightFill
                    onClick={() => {
                      setModal((prev) => !prev);
                    }}
                    style={{
                      marginLeft: "0.5em",
                      fontSize: "0.8em",
                      cursor: "pointer",
                    }}
                  />
                </p>
              </div>

              {isInCart ?
                <Button
                  title="Remove from cart"
                  variant="btn-darkFill"
                  onClick={() => {dispatch(removeCart(parseInt(productId)))}}
                />
              : (
                <Button
                  title="Add to Bag"
                  variant="btn-darkFill"
                  onClick={() =>
                    isLoggedIn ? selectedSize == null ? btToast({message: 'Please select size first'}) : dispatch(addCart({ id: parseInt(productId), size: selectedSize })) : navigate(`/login?returnTo=product-detail/${productId}`)
                  }
                />
              )}

              <div className="flex-column gap-2 w-100">
                <DropdownBox
                  title="DESCRIPTION"
                  content={product?.description}
                />

                {/* <DropdownBox
                title="DETAILS"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip ex
                            ea commodo consequat."
              /> */}
                <DropdownBox
                  title="CARE GUIDE"
                  // content={'s fjdlfjldfjlsjldsf'}
                  content={product?.care_guide?.join("</br>")}
                />
                <DropdownBox
                  // status={false}
                  title="SHIPPING AND RETURN"
                  content={product?.shipping_returns}
                />
              </div>
            </div>
          </div>

          <div className="ymalSection w-100 flex-centered flex-column">
            <h2>You May Also Like</h2>
            <div className="ymalContent flex-centered gap-2">
              {productList?.map((product, ind) => (
                <ProductCard details={product} path={imagePath?.image_path} key={product?.id} />
              ))}
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
}

export default ProductDetail;

const ModalComponent = (props) => {
  return (
    <>
      <div
        className="popupSection"
        style={{
          display: props.toggle ? "block" : "none",
        }}
      >
        <div className="popupBackground"></div>
        <div className="popupCloseBtn">
          <button className="closeBtn" onClick={props.handleToggle}>
            <CgClose
              style={{
                fill: "#fff",
                fontSize: "1.5em",
                cursor: "pointer",
                color: "white",
              }}
            />
          </button>
        </div>
        <div className="w-100">{props.children}</div>
      </div>
    </>
  );
};

const MeasurementCompo = (props) => {
  const measurement=[
    {
      sizeHeading: "XS",
      bust: "34",
      waist: "30",
      hips: "38",
      shoulder: "14",
      armHole: "15.5",
    },
    {
      sizeHeading: "S",
      bust: "36",
      waist: "32",
      hips: "40",
      shoulder: "14.5",
      armHole: "16.5",
    },
    {
      sizeHeading: "M",
      bust: "38",
      waist: "34",
      hips: "42",
      shoulder: "15",
      armHole: "17",
    },
    {
      sizeHeading: "L",
      bust: "40",
      waist: "36",
      hips: "44",
      shoulder: "16.5",
      armHole: "17.5",
    },
    {
      sizeHeading: "XL",
      bust: "42",
      waist: "38",
      hips: "46",
      shoulder: "17",
      armHole: "18",
    },
    // {
    //   sizeHeading: "XXL",
    //   bust: " ",
    //   waist: " ",
    //   hips: " ",
    //   shoulder: " ",
    //   armHole: " ",
    // },
  ]
  return (
    <>
      <div className="measurementDiv">
        <div className="measurementBodyTypes flex-column gap-1 w-100">
          <p>SINGLE SIZE (WOMENSWEAR)</p>

          <p>BUST</p>

          <p>WAIST</p>

          <p>HIPS</p>

          <p>SHOULDER</p>

          <p>ARM HOLE</p>
        </div>
        {measurement?.map((itm, index) => {
          return (
            <>
              <div className="flex-column gap-1" key={index}>
                <p>{itm.sizeHeading}</p>

                <p>{itm.bust}</p>

                <p>{itm.waist}</p>

                <p>{itm.hips}</p>

                <p>{itm.shoulder}</p>

                <p>{itm.armHole}</p>
              </div>
            </>
          );
        })}
      </div>
      <br/>
      <hr/>
      <p>The fit varies with each product, few are fitted, few comfort fit, few loose. You are advised to select your size in accordance with the fit of the product.</p>
    </>
  );
};

const ProductImgSection = (props) => {
  const [imgSrc, setImgSrc] = useState(props.img)
  return (
    <div
      className="productDetailImgDiv gap-1 flex-centered"
      style={{ paddingTop: PRODUCT.paddingTop }}
    >
      <div className="productDetailImgAbsDiv flex-centered">
        <img
          src={imgSrc}
          onError={() =>setImgSrc(PRODUCT.defaultImg)}
        />
      </div>
    </div>
  );
};

const DropdownBox = (props) => {
  const [dropdownBoxToggle, setDropdownBoxToggle] = useState(props.status);

  const handleDropdownBoxToggle = () => {
    setDropdownBoxToggle((prev) => !prev);
  };

  return (
    <>
      <div className="dropdownBoxSection flex-column">
        <hr />

        <button className="dropdownButton" onClick={handleDropdownBoxToggle}>
          {props.title}
          {!dropdownBoxToggle ? <BsCaretRightFill /> : <BsCaretDownFill />}
        </button>

        <div className="dropdownBoxText flex">
          <p
            style={{
              height: dropdownBoxToggle ? "fit-content" : "0em",
            }}
          >
            {useTextLimit(props?.content, 200)}
          </p>
        </div>
      </div>
    </>
  );
};
DropdownBox.defaultProps = {
  status: true,
};