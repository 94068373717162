

import './style.css'
const CustomButton=(props)=>{

    return <>
    <button 
    className='bt-btn bt-dark'
    onClick={props.onClick}
    disabled={props.disabled || props.loading}
    >{(props?.loading)?'Loading...':props.title}</button>
    </>
}

export default CustomButton;
CustomButton.defaultProps={
    title:'Click',
    onClick:()=>null,
    disabled:false,
    loading:false
}