import { useState } from 'react'
import './ShortBanner.css'
import {BsArrowRight} from '@react-icons/all-files/bs/BsArrowRight.esm'
import { useNavigate } from 'react-router-dom'

function ShortBanner(props) {
    const [payload, setPayload] = useState(null)
    const navigate = useNavigate()

  return (
    <div className='shortBannerSection flex-centered'>
        <div className='shortBannerTextDiv flex-centered gap-5'>
            {props.title && (
                <p className='p-txt' style={{fontWeight: '400', fontSize: '1.5em', letterSpacing:'0.1em'}}>{props.title}</p>
            )}
            {props.input && (
                <div className='flex inputDiv'>
                    <input 
                        type='text' 
                        className='input' 
                        placeholder={props.hint} 
                        name={props?.name}
                        onChange={(e)=>setPayload(e?.target?.value)}
                    />
                    
                    <BsArrowRight 
                        style={{fontSize: '3em', cursor: 'pointer'}}
                        onClick={()=>navigate('/sign-up?returnTo=offer', {state: {data: payload}})}
                    />
                </div>
            )}
        </div>
    </div>
  )
}

export default ShortBanner