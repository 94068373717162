import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RootLayout from "../Layout";
import { FieldContainer, Form, FormActions, Input } from "../commonComponent/Form";
import './style.css'
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getLogin } from "../../store/authSlice";
import CustomButton from "../commonComponent/Button/CustomButton";
import { btToast } from "../components/BtToast";

const Login=()=>{

    const [payLoad, setPayload] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [errors,setErrors]=useState(null)
    const [formError,setFormErrors]=useState(null)
    let [searchParams, setSearchParams] = useSearchParams();
    const returnTo =searchParams.get('returnTo') || '';
    const location = useLocation()
    
    const navigate = useNavigate();

    const handleChange = (name,value) => {
        setFormErrors(false)
        setPayload({
            ...payLoad,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]:null
        })
    }

    async function handleLogin(){
        setLoading(true)
        dispatch(getLogin(payLoad,navigate))
            .then((resp) => {
                if(resp?.status === 200){
                    btToast({type:'success',message:"Logged in successfully"})
                    if(returnTo!=''){
                        window.location.href=`/`+returnTo
                    }else{
                        window.location.href='/profile'
                    }
                }else{
                    btToast({message: resp?.response?.data?.message})
                }
            })
            .catch((err) => {
                btToast({message: err?.response?.data?.message})
                setLoading(false)
                setFormErrors(err?.response?.data?.message)
                setErrors(err?.response?.data?.errors)
            })
            .finally(()=>setLoading(false))
    }
    return <RootLayout>
        <Form heading='LOGIN' style={{margin:'50px auto',maxWidth:'450px'}}>
            <FieldContainer>
                <Input 
                    label="EMAIL"
                    name='email' 
                    error={errors?.email}
                    placeholder='user@example.com'
                    handleChange={handleChange}
                />
                <Input 
                    label="PASSWORD" 
                    error={errors?.password}
                    type="password"
                    name='password'
                    placeholder='Enter your password'
                    handleChange={handleChange}
                />
            </FieldContainer>
            <FormActions>
                <CustomButton
                    title='Login'
                    onClick={handleLogin}
                    loading={loading}
                />
            </FormActions>
            <div className="login-links">
            <NavLink className='left' to={`/sign-up?returnTo=${returnTo}`}>Did'nt have an account? Register here</NavLink>
            <NavLink className='right' to={`/forgot-password?returnTo=${returnTo}`}>Forget Password ?</NavLink>
            </div>

        </Form>
    </RootLayout>

}

export default Login;