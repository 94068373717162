import React, { useEffect, useState } from 'react'
import './style.css'
import Input from '../commonComponent/Input/Input';
import Button from '../commonComponent/Button/Button';

import customerService from '../../services/axios/customer';
import RootLayout from '../Layout';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { btToast } from '../components/BtToast';
import { toNull } from '../../utils/commonFunctions';

const CustDetailForm =() => {
    const location = useLocation()
    const [payload, setPayload] = useState({});
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const [searchParams, setSearchParams] = useSearchParams()
    const returnTo =searchParams.get('returnTo') || '';
    const navigate = useNavigate()

    useEffect(()=>{
        customerService?.getDetails()
            .then((res) => {
                setPayload(res?.data?.response?.details)
            })
            .catch((err) => {
                
            })
    },[])

    const handleChange = (name, value) => {
        if(name === 'is_billing_address_same'){
            if(value){
                setPayload({
                    ...payload,
                    [name]: value,
                    ['billing_address1']: payload?.address1,
                    ['billing_address2']: payload?.address2,
                    ['billing_city']: payload?.city,
                    ['billing_state']: payload?.state,
                    ['billing_country']: payload?.country,
                    ['billing_zipcode']: payload?.zipcode,
                })
            }else{
                setPayload({
                    ...payload,
                    [name]: value,
                });
            }
        }else{
            setPayload({
                ...payload,
                [name]: value,
            });
        }
        setErrors({
            ...errors,
            [name]: null
        })
    };

const handleSubmit = () => {
    setLoading(true);
    customerService?.updateProfile(payload)
        .then((res) => {
            btToast({type:'success',message:res?.data?.message})
            if(returnTo === "cart"){
                navigate('/cart')
            }else if(toNull(location?.state?.returnTo)!=null && location?.state?.returnTo!='offer'){
                window.location.href=`/`+location?.state?.returnTo
            } else{
                navigate('/profile')
            }
        })
        .catch((err) => {
            btToast({message: err?.response?.data?.message})
            setErrors(err?.response?.data?.errors)
        })
        .finally(()=>{setLoading(false)})
}
    
    return (
        <RootLayout>
            <div className='custDetailFormSection'>
                <div className="custDetailForm flex-column flex gap-1">
                    <div className='flex-centered'>
                        <h2>Add your Details</h2>
                    </div>
                    <br/>
                    <Container heading="Personal Info">
                        <RowContainer >
                            <Input
                                name="name"
                                label="Name"
                                value={payload?.name}
                                error={errors?.name}
                                handleChange={handleChange}
                            />

                            <Input
                                name="email"
                                label="Email"
                                value={payload?.email}
                                error={errors?.email}
                                handleChange={handleChange}
                            />
                        </RowContainer>

                        <RowContainer >
                            <Input
                                name="mobile"
                                label="Mobile"
                                value={payload?.mobile}
                                error={errors?.mobile}
                                handleChange={handleChange}
                            />
                        </RowContainer>
                    </Container>
                    <br/>
                    <br/>
                    <Container heading="Shipping Address">
                        <RowContainer >
                            <Input
                                name="address1"
                                label="House/Apt/Floor No."
                                value={payload?.address1}
                                error={errors?.address1}
                                handleChange={handleChange}
                            />

                            <Input
                                name="address2"
                                label="Street/Colony Name"
                                value={payload?.address2}
                                error={errors?.address2}
                                handleChange={handleChange}
                            />
                        </RowContainer>

                        <RowContainer >
                            <Input
                                name="city"
                                label="City"
                                value={payload?.city}
                                error={errors?.city}
                                handleChange={handleChange}
                            />

                            <Input
                                name="state"
                                label="State"
                                value={payload?.state}
                                error={errors?.state}
                                handleChange={handleChange}
                            />
                        </RowContainer>

                        <RowContainer >
                            <Input
                                name="country"
                                label="Country"
                                value={payload?.country}
                                error={errors?.country}
                                handleChange={handleChange}
                            />

                            <Input
                                name="zipcode"
                                label="Zip Code"
                                value={payload?.zipcode}
                                error={errors?.zipcode}
                                handleChange={handleChange}
                            />
                        </RowContainer>
                    </Container>
                    <br/>
    
                    <div className='flex gap-1'>
                        <input 
                            type="checkbox"
                            name="is_billing_address_same"
                            checked={payload?.is_billing_address_same}
                            onChange={(e)=>handleChange('is_billing_address_same', e?.target?.checked)}
                        />
                        <p>If your billing address same as your current addess</p>
                    </div>

                    <Container heading="Billing Address">
                        <RowContainer >
                            <Input
                                name="billing_address1"
                                label="House/Apt/Floor No."
                                disabled={payload?.is_billing_address_same}
                                value={payload?.is_billing_address_same ? payload?.address1 : payload?.billing_address1}
                                error={errors?.billing_address1}
                                handleChange={(name,value)=>handleChange('billing_address1',payload?.is_billing_address_same ? payload?.address1 : payload?.billing_address1)}
                            />

                            <Input
                                name="billing_address2"
                                label="Street/Colony Name"
                                disabled={payload?.is_billing_address_same}
                                value={payload?.is_billing_address_same ? payload?.address2 : payload?.billing_address2}
                                error={errors?.billing_address2}
                                handleChange={handleChange}
                            />
                        </RowContainer>

                        <RowContainer >
                            <Input
                                name="billing_city"
                                label="City"
                                value={payload?.is_billing_address_same ? payload?.city : payload?.billing_city}
                                disabled={payload?.is_billing_address_same}
                                error={errors?.billing_city}
                                handleChange={handleChange}
                            />

                            <Input
                                name="billing_state"
                                label="State"
                                value={payload?.is_billing_address_same ? payload?.state : payload?.billing_state}
                                disabled={payload?.is_billing_address_same}
                                error={errors?.billing_state}
                                handleChange={handleChange}
                            />
                        </RowContainer>

                        <RowContainer >
                            <Input
                                name="billing_country"
                                label="Country"
                                disabled={payload?.is_billing_address_same}
                                value={payload?.is_billing_address_same ? payload?.country : payload?.billing_country}
                                error={errors?.billing_country}
                                handleChange={handleChange}
                            />

                            <Input
                                name="billing_zipcode"
                                label="Zip Code"
                                disabled={payload?.is_billing_address_same}
                                value={payload?.is_billing_address_same ? payload?.zipcode :payload?.billing_zipcode}
                                error={errors?.billing_zipcode}
                                handleChange={handleChange}
                            />
                        </RowContainer>
                    </Container>
                    <br/>
                    <br/>
                    <div className='custDetail-btn flex-centered w-100'>
                        <Button 
                            title="Submit" 
                            variant="btn-darkFill" 
                            onClick={handleSubmit} 
                            buttonLoading={loading}
                        />
                    </div>
                    
                </div>
            </div>
        {/* </AuthBgComponent> */}
        </RootLayout>
    )
}

export default CustDetailForm

const RowContainer = (props) => {
    
    return(<>
        <div className={`row-container-component w-100 ${props.className}`}>
            {props.children}
        </div>
    </>)
}

const Container = (props) => {
    
    return(<>
        <div className={`container-component w-100 flex-column flex gap-1 ${props.className}`}
        >
            <div className='w-100' style={{
                textAlign: 'left',
                paddingBottom: '1em'
            }}>
                <h3>{props.heading}</h3>
            </div>
            {/* <hr style={{
                background: 'rgba(0,0,0,0.3)',
                margin: '0.5em 0'
            }}/> */}
            {props.children}
        </div>
    </>)
}