import React, { useEffect, useState } from 'react'
import './MyProfile.css'
import RootLayout from '../Layout'
import CustImg from '../../assets/images/person.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { getDetails } from '../../store/customerSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { getLogout } from '../../store/authSlice'
import orderService from '../../services/axios/order'
import { toCurrencyFormat } from '../CommonFunction/CurrencyFormat'
import DefaultImage from '../../assets/default/product-400x500.png'
import { PRODUCT } from '../../constants'
import { MdModeEditOutline } from "react-icons/md";
import { RiSettings4Fill } from "react-icons/ri";
import { RiLogoutCircleLine } from "react-icons/ri";
import { confirm } from '../components/BTUI/BtAlert'

function MyProfile() {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const {profile}=useSelector((store) => store.customer);
    const {id} = useParams();
    const {isLoggedIn}=useSelector((store) => store.auth);
    const [userData, setUserData] = useState({})
    const [userOrders, setUserOrders] = useState([])
    const [imagePath, setImagePath] = useState([])

    useEffect(() => {
        dispatch(getDetails({id}))
            .then((resp) => {
                setUserData(resp?.response)
            })
            .catch((err) => {
            })
    },[])

    useEffect(() => {
        orderService?.list()
            .then((resp) => {
                setUserOrders(resp?.data?.response?.list)
                setImagePath(resp?.data?.response)
            })
            .catch((err) => {
            })
    }, [])
    
    // const handl
    
    return (<>
        <RootLayout>
            <div className='myProfileSection flex-centered flex-column gap-2 w-100'>
                <div className='flex-column gap-2 w-80' >
                    <div className='flex gap-2 w-100'>
                        <div className='myProfileOrdersSection w-70 flex-column flex-centered gap-1'>
                            <h2>My Orders</h2>
                            <hr/>
                            {userOrders?.length>0 ? <div className='myprofileOrderDiv w-100 gap-1'>
                                {userOrders?.map((orders, i) => {
                                    return(
                                        <OrderCard 
                                            // maxWidth={i===0 ? '0em': '0em'}
                                            img={imagePath?.thumb_path+orders?.product_detail?.[0]?.primary_image}
                                            price={toCurrencyFormat(orders?.grand_total)}
                                            name={`${orders?.product_detail?.[0]?.name} ${orders?.product_detail?.length > 1 ? "("+(orders?.product_detail?.length-1)+" more products)" : ''} `}
                                            quantity={orders.product_detail?.[0]?.qty}
                                            id={orders?.id}
                                            uid={orders?.uid}
                                            alt=""
                                            status={orders?.payment_status}
                                        />
                                    )
                                }) }
                                </div> : <p>You haven't placed any order yet</p>}
                        </div>

                        <div className='myProfileInfo w-30 flex-column gap-1'>
                            <div className='w-100 flex-between'>
                                <h2>My Profile</h2>
                                
                                <div className="" style={{display: 'flex', gap: '1em', justifyContent: 'center', alignItems: 'center'}}>
                                    <RiSettings4Fill style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={()=>navigate('/update-password')}/>
                                    <MdModeEditOutline style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={()=>navigate('/personal-info')}/>
                                    <RiLogoutCircleLine style={{fontSize: '1.3em', cursor: 'pointer'}} onClick={()=>confirm(
                                        'You want to Logout',
                                        ()=>dispatch(getLogout(navigate)),
                                        ()=>{},
                                        {
                                            acceptTitle:'Logout',
                                            acceptBackground: "#ef3f49"
                                        }
                                    )}/>
                                </div>
                            </div>
                            <hr/>
                            <div className='flex-column gap-2 flex-centered'>
                                <div className='myProfileInfoImg'>
                                    <img src={CustImg} width={150} />
                                </div>
                                <div className='myProfileInfoTxt flex-column gap-1'>
                                    <h3>{profile?.name}</h3>
                                    <p>{profile?.email}</p>
                                    <p>+91 {profile?.mobile}</p>
                                </div>
                                
                            </div>
                            <hr/>
                            <div className='myProfileInfoTxtAddress flex-column gap-1'>
                                <div className=''>
                                    <h3>Shipping Address</h3>
                                    <p>{`${(profile?.address1 || profile?.address2) && `${profile?.address1} ${profile?.address2},`} `}</p>
                                    <p>{(profile?.city||profile?.state) && `${profile?.city}, ${profile?.state}`}</p>
                                    <p>{(profile?.zipcode||profile?.country)&&`${profile?.zipcode}, ${profile?.country}`}</p>
                                </div>
                                <div className=''>
                                    <h3>Billing Address</h3>
                                    <p>{(profile?.billing_address1||profile?.billing_address2)&&`${profile?.billing_address1} ${profile?.billing_address2},`}</p>
                                    <p>{(profile?.billing_city||profile?.billing_state)&&`${profile?.billing_city}, ${profile?.billing_state}`}</p>
                                    <p>{(profile?.billing_zipcode||profile?.billing_country)&&`${profile?.billing_zipcode}, ${profile?.billing_country}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </RootLayout>
    </>)
}

export default MyProfile

const OrderCard = (props) => {
    const [defaultImage, setDefaultImage] = useState(props?.img);

    const navigate = useNavigate()

    return (
        <div className="orderCardSection flex-column" onClick={()=> navigate(`/my-profile/my-order-detail/${props.uid}`)} style={{width: '0'}}>
            <div className='orderCardImg' style={{paddingTop: PRODUCT?.paddingTop}}>
                <div className='orderCardPriceAbs'>{props.price}</div>
                <div className='orderCardStatusAbs'
                    style={{
                        background: props?.status == 'PENDING' ? 'rgba(255, 251, 0, 0.8)' : 
                                    props?.status == 'SUCCESS' ? 'rgba(44, 150, 73, 0.8)' : 
                                    props?.status == 'FAILED' || props?.status == 'FAILURE' ? 'rgba(230, 57, 57, .8)' :
                                    props?.status == 'ABORTED' ? 'rgba(230, 57, 57, .8)' : 'white',
                        fontSize: '0.9em',
                        
                    }}
                >{props.status}</div>
                <div className='orderCardImgAbs'>
                    <img src={defaultImage} onError={()=>setDefaultImage(DefaultImage)} />
                </div>
            </div>

            <div className='orderCardTxt'>
                <h2>{props.name}</h2>
                <p>Quantity - {props.quantity}</p>
            </div>
        </div>
    )
}