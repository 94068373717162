import React, { useEffect, useState } from 'react'
import RootLayout from '../../Layout'
import './MenProduct.css'
import { useDispatch} from 'react-redux';
import { getFavoriteParoducts} from '../../../store/productSlice';
import ProductCard from '../../commonComponent/Product/Product';
import productService from '../../../services/axios/product';

function MenProduct() {

    const dispatch = useDispatch();

    const [productList, setProductList] = useState({});


    // useEffect(() => {
    //     dispatch(getFavoriteParoducts({}))
    // }, [])

    useEffect(() => {
        productService.list({category:'Men',pageSize:100})
            .then((resp) => {
                setProductList(resp?.data?.response);
            })
    }, [])

    return (
        <RootLayout >
            <div className="menProductSection w-100 flex-centered flex-column gap-1">
                <div className="menProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <div className="menProductHeading">
                        <p>CATEGORIES / MEN </p>
                        <h2>Shop All</h2>
                    </div>
                    {/* <div className="menProductHeaderBottom flex flex-between">
                        <div className="menProductFilter">
                            <div className='menProductFilterTxt flex'>
                                <p>FILTERS </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="menProductDropMenu">
                                <li><NavLink>Filter 1</NavLink></li>
                                <li><NavLink>Filter 2</NavLink></li>
                                <li><NavLink>Filter 3</NavLink></li>
                                <li><NavLink>Filter 4</NavLink></li>
                            </ul>
                        </div>
                        <div className="menProductSortBy">
                            <div className='menProductSortByTxt flex'>
                                <p>SORT BY </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="menProductDropMenu">
                                <li><NavLink>Sort By 1</NavLink></li>
                                <li><NavLink>Sort By 2</NavLink></li>
                                <li><NavLink>Sort By 3</NavLink></li>
                                <li><NavLink>Sort By 4</NavLink></li>
                            </ul>
                            <div className="menProductGrid">

                            </div>
                        </div>

                    </div> */}
                </div>
                <div className="menProductContent flex-centered flex-column gap-3">

                    <div className='flex-centered w-100'>
                        {productList?.list?.map((product) => <ProductCard 
                                            details={product}
                                            path={productList?.thumb_path}
                                            key={product?.id} 
                                        />)
                        }
                    </div>

{/* 
                    <div className='flex-centered productBtnDiv '>
                        <Button
                            title="+ VIEW MORE"
                            variant="btn-dark"
                        />
                    </div> */}

                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </RootLayout>

    )
}

export default MenProduct