import './Style.css'
import PoliciesContainer from '../Index';


const TermAndCondition = () => {

    return <>
        <PoliciesContainer
            heading="Term and conditions"
        >
            <p>At <b>Akriti by Shakun</b> we value and share your concerns about your privacy and security. By visiting us, the user has agreed to read, browse and access all our information subject to the guidelines and terms of use applicable to such services. If it is not acceptable, kindly refrain from accessing or visiting our Site.</p>
            <p>If you visit or shop at Akriti by Shakun, you accept these conditions. Please read them carefully.</p>

            
            <p>
                <h3>COPYRIGHT</h3>
                All content included on this Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Akriti by Shakun or its content suppliers. The compilation of all content on this site is the exclusive property of Akriti by Shakun. All software used on this Site is the property of Akriti by Shakun or its software suppliers.</p>

            
            <p>
                <h3>TRADEMARKS</h3>
                Use of Akriti by Shakun and its contents grants no rights to you in relation to any  copyright, designs, trademarks and all other intellectual property and material rights. Any violation of our trademarks will be liable for legal action. </p>

            
            <p>
                <h3>ELECTRONIC COMMUNICATIONS</h3>
                When you use our Site or send emails or other data, information or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive communications via electronic records from us periodically. We will communicate with you by email or by an electronic record on our Site which will be deemed adequate service of notice/electronic record.</p>

            
            <p>
                <h3>ORDERS AND CANCELLATIONS</h3> 
                We reserve a right to accept or reject orders placed through our site. We have tried our utmost best to provide the best quality products to you. In case of requests for order cancellations, as part of our policy, the orders once placed will not be cancelled/exchanged and no refund will be paid on these orders as these orders are specially made for you.</p>

            <p>
                <h3>SIZING</h3>
                The fit of our products may vary on the cut, fabric and style of each product. Some products are designed to have a loose, relaxed fit while others are more fitted. Please refer to our sizing chart to select the best size that suits you.</p>
            
            <p>
                <h3>COLORS</h3>
                While we have made every effort to display as accurately as possible the colors of the products that appear on the Site, we cannot guarantee that your monitor or screen’s display of any color will be completely accurate, as computer monitors and screens of electronic devices vary. Further, colors might vary in pictures than actual product.
            </p>
            
            <p>
                <h3>DELIVERY</h3>
                Product will be delivered in 35-40 days depending on the outfit ordered. For urgent deliveries/queries, please contact us on <b>+91 98882 24142</b>. Please note that deliveries can be affected in case we do not receive any of the below information on time:

                <br/>
                <b>Information:</b><br/>
                1. Complete payment is not received<br/>
                2. Incorrect/inaccurate address details<br/>
                3. Measurements<br/>
                4. No response from the customer<br/>
                Please note that we try our best in ensuring that the product reaches you within time. In order to fasten our process, we have tied up with several third party vendors for quicker deliveries. However, in case of delay from a third party, we do not take any responsibility. 
            </p>

            <p>
                <h3>SHIPPING </h3>
                We ship worldwide. The shipping charges for all shipping within India  is completely borne by customer. However, the shipping charges for international shipping will be additional and will be charged based on the weight and volume of the product and the country where the delivery is being dispatched. Shipping charges will be shared upon inquiry.</p>
            
            <p>
                <h3>SALE</h3>
                Akriti by Shakun can offer some of its products on promotional prices. However, we note that the sale outfits cannot be exchanged/returned strictly. Advance received for a sale outfit cannot be reimbursed/refunded under any circumstances. Alteration/resizing of sale outfits will not be done for any outfits offered on sale.
            </p>

            <p>
                <h3>DELIVERY POLICIES</h3>
                Akriti by Shakun uses third party logistics and dispatches for all its courier services. Akriti by Shakun is not responsible for the timely delivery of the products in case of any delay from the logistics team. 
                <br/>
                For all delivery within India,  Akriti by Shakun bears the costs of shipping services. However, international shipping charges are requested to be paid by the client.
                <br/>
                We do not offer  'Cash on Delivery' services.  Therefore, when placing the order there will be full amount deductible to the total order value. 
            </p>

            <p>
                <h3>UNDELIVERED OUTFITS</h3>
                Akriti by Shakun will dispatch the outfits as promised to the client upon receipt of full payment. If the client wants to collect the outfit from  Akriti by Shakun store, the outfit will remain with  Akriti by Shakun  store until the said delivery date. However, if the outfit is undelivered and returned back to us or has not been picked by the client,  Akriti by Shakun will re-connect with the client to re-attempt the delivery. In the event that we are unable to contact the client, we will hold the outfit with us for a period of 6 months. In case of no communication/response from client over this 6 month period,  Akriti by Shakun will forfeit the amount paid on the outfit and the outfit will be discarded.
            </p>

            <br/>
            <br/>
        </PoliciesContainer>
        
    </>
}

export default TermAndCondition;