import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../Layout";
import { FieldContainer, Form, FormActions, Input } from "../commonComponent/Form";
import './style.css'
import { useState } from "react";
// import { useDispatch } from "react-redux";
import CustomButton from "../commonComponent/Button/CustomButton";
import authService from "../../services/axios/auth";
import { btToast } from "../components/BtToast";

const VerifyForgotOtp=()=>{
    const location = useLocation()
    const [payLoad, setPayload] = useState({email: location?.state?.email});
    const [errors,setErrors]=useState(null)
    // const returnTo =searchParams.get('returnTo') || '';
    const [buttonLoading, setButtonLoading] = useState(false)
    const navigate = useNavigate();

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]:null
        })
    }

    const handleVerify=()=>{
        setButtonLoading(true)
        authService?.verifyForgotOtp(payLoad)
            .then((resp) => {
                btToast({type:'success',message:resp?.data?.message})
                navigate('/login')
            })
            .catch((err) => {
                btToast({message:err?.response?.data?.message})
                // setErrors(err?.response?.data?.errors)
            })
            .finally(()=>setButtonLoading(false))
    }
    return <RootLayout>
        <Form heading='Forgot Password' style={{margin:'50px auto',maxWidth:'450px'}}>
            <FieldContainer>
                <Input 
                    name="email"
                    label='Email'
                    value={payLoad?.email}
                    error={errors?.email}
                    handleChange={handleChange}
                />
                <Input 
                    name="code"
                    label='Otp'
                    value={payLoad?.code}
                    error={errors?.code}
                    handleChange={handleChange}
                />
                <Input 
                    name="password"
                    label='New Password'
                    type="password"
                    value={payLoad?.password}
                    error={errors?.password}
                    handleChange={handleChange}
                />
            </FieldContainer>
            <FormActions>
                <CustomButton
                    loading={buttonLoading}
                    title='Next'
                    onClick={handleVerify}
                />
            </FormActions>
        </Form>
    </RootLayout>

}

export default VerifyForgotOtp;