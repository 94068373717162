import PoliciesContainer from '../Index'
import './Style.css'

const PrivacyPolicy = () => {

    return<>
        <PoliciesContainer
            heading="ORDERS & SHIPMENT"
        >
            <p>
                {/* <h3>ORDERS & SHIPMENT</h3> */}
                The shipping charges for all shipping within India is completely borne by customer. However, the shipping charges for international shipping will be additional and will be charged based on the weight and volume of the product and the country where the delivery is being dispatched. Shipping charges will be shared upon inquiry. 
            </p>

            <p>
                <h3>TAXES & DUTIES</h3>
                The product prices displayed are at maximum retail price. In case of international deliveries, we ship on a DDU (Delivery Duty Unpaid) basis, which means product prices displayed are exclusive of all import duties. As the recipient, you are liable for all import duties, customs and local sales taxes levied by the country you are in, payment of these at the time of delivery is necessary to release your order from customs on arrival.
            </p>
        </PoliciesContainer>
    </>
}

export default PrivacyPolicy