import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RootLayout from "../Layout";
import { FieldContainer, Form, FormActions, Input } from "../commonComponent/Form";
import './style.css'
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getSignup } from "../../store/authSlice";
import CustomButton from "../commonComponent/Button/CustomButton";
import { btToast } from "../components/BtToast";

const SingUp=()=>{
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    const returnTo = searchParams.get('returnTo') || '';
    const [payLoad, setPayload] = useState({
        email: location?.state?.data
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [errors,setErrors]=useState(null)
    const [formError,setFormErrors]=useState(null)
    
    
    
    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]:null
        })
    }

    async function handleSignup(){
        setLoading(true)
        if(payLoad?.password===payLoad?.conPass){
            dispatch(getSignup(payLoad))
            .then((resp) => {
                if(resp?.status === 200){
                    if(returnTo!='' && returnTo!='offer'){
                        window.location.href=`/${returnTo}?email=${payLoad?.email}`
                    }else{
                        // navigate('/verify-email', {state: {email: payLoad?.email, returnTo: returnTo}})
                        window.location.href=`/verify-email?email=${payLoad?.email}`
                    }
                }else{
                    setErrors(resp?.response?.data?.errors)
                }
            })
            .catch((err) => {
                setFormErrors(err?.response?.data?.message)
                setErrors(err?.response?.data?.errors)
            })
            .finally(()=>setLoading(false))
        }else{
            setLoading(false)
            btToast({message:"Please fill all the fields"})
        }
        
    }
    return <RootLayout>
        <Form heading='SIGN UP' style={{margin:'50px auto',maxWidth:'450px'}}>
            <FieldContainer>
                <Input 
                    name="name"
                    label='Name'
                    value={payLoad?.name}
                    error={errors?.name}
                    handleChange={handleChange}
                />
                <Input 
                    name="mobile"
                    label='Phone'
                    value={payLoad?.mobile}
                    error={errors?.mobile}
                    handleChange={handleChange}
                />

                <Input 
                    name="email"
                    label='Email'
                    value={payLoad?.email}
                    error={errors?.email}
                    handleChange={handleChange}
                />

                <Input 
                    name="password"
                    label='Password'
                    type="password"
                    value={payLoad?.password}
                    error={errors?.password}
                    handleChange={handleChange}
                />

                <Input 
                    name="conPass"
                    label='Confirm Password'
                    type="password"
                    value={payLoad?.conPass}
                    error={payLoad?.conPass == payLoad?.password ? "" : "Password dosen't match "}
                    handleChange={handleChange}
                />
            </FieldContainer>
            <FormActions>
                <CustomButton
                    title='Sign up'
                    onClick={handleSignup}
                    loading={loading}
                />
            </FormActions>
            <div className="login-links">
            <NavLink className='left' to={`/login?returnTo=${returnTo}`}>Already have an account?Login</NavLink>
            </div>

        </Form>
    </RootLayout>

}

export default SingUp;