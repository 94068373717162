import PoliciesContainer from "../Index"


const Designer = () => {

    return<>
        <PoliciesContainer
            heading="The Designer"
        >
            <p><b>Shakun Garg </b>is a visionary designer whose creative prowess knows no bounds. With a career spanning over a decade, Shakun has carved a niche for himself in the world of fashion, redefining elegance with each of his exquisite creations. His journey as a designer began in 2006, fueled by a relentless passion to bring his imagination to life through the art of fashion.</p>

            <p>Hailing from the vibrant city of Patiala, Shakun draws inspiration from the rich cultural tapestry of his surroundings, a keen eye for design, often spending hours sketching and experimenting with fabrics. His designs, characterized by intricate embellishments, luxurious fabrics, and impeccable tailoring, have graced the red carpets of Bollywood and Punjabi Industry, earning him a loyal following of discerning clientele. Whether it's the bustling streets of Patiala or the majestic architecture of its historic monuments, Shakun finds beauty in the smallest of details, infusing his designs with a sense of authenticity and nostalgia that resonates with audiences worldwide.</p>

            <p>In April 2022, Shakun proudly opened the doors to the first flagship store of Akriti by Shakun in his hometown of Patiala, signaling a new chapter in his journey as a designer. This expansion not only showcases his remarkable achievements but also provides fashion enthusiasts with a firsthand experience of his unparalleled artistry.</p>

            <p>Shakun's designs continue to pay homage to India's rich cultural heritage, with collections like Sheesh Mahal, Qila Chowk and Rah-e-virasat resonating with audiences worldwide. Through his artistry, Shakun weaves together stories of tradition, heritage, and elegance, leaving an indelible mark on the world of fashion and inspiring generations to come.</p>

            <p>With each collection, he pushes the boundaries of creativity, infusing his designs with a unique blend of tradition and modernity that sets him apart in the competitive world of fashion.</p>
        </PoliciesContainer>
    </>
}

export default Designer