import './globals.css'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import ShortBanner from './components/ShortBanner/ShortBanner'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const metadata = {
  title: 'Create Next App',
  description: 'Generated by create next app',
}

export default function RootLayout(props) {
  const {pathname} = useLocation()
  const {isLoggedIn} = useSelector(store => store.auth)
  
  useEffect(()=>{
    window.scrollTo(0,0);
  },[pathname])


  
  return <>
      <Navbar />
          <div style={{minHeight:'50vh'}}>
            {props.children}
          </div>

          {!isLoggedIn && <ShortBanner 
            title="SIGN UP & SAVE 10% ON YOUR PURCHASE"
            input={true}
            hint="Enter your email here"
            name="email"
            btnTitle=">"
          />}
          
      <Footer />
      </>
}
